import { css } from '@emotion/react';
import { Alert, Box, Button, Link, Typography } from '@mui/material';

import { AsynchronouslyLoadedSection } from 'src/components';
import { useGetUserDetails } from 'src/hooks';
import { useGetContinueStripeRegistrationLink } from 'src/hooks/useGetContinueStripeRegistrationLink';
import { AccountStatus } from 'src/types/account';

export const OnboardingCard = () => {
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails, isOnboarding } = userData ?? {};

  const { data: stripeOnboarding, isLoading: isLoadingOnboardingLink } =
    useGetContinueStripeRegistrationLink();

  const isLoading = isUserLoading || isLoadingOnboardingLink;

  if (!isOnboarding) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      css={css`
        width: 100%;
        .MuiAlert-message {
          flex: 1 1 auto;
        }
      `}
    >
      <Box
        css={css`
          display: flex;
          gap: 40px;
          align-items: flex-start;
        `}
      >
        <AsynchronouslyLoadedSection isLoading={isLoading}>
          {accountDetails?.status === AccountStatus.PENDING_ONBOARDING ? (
            <>
              <Typography>
                <strong>Welcome to Benji Card!</strong> Please finish onboarding
                to unlock the full functionality of your account. If you have
                any questions or concerns, please reach out to us at{' '}
                <Link href="mailto:support@benjicard.com">
                  support@benjicard.com
                </Link>
                .
              </Typography>
              <Button
                href={stripeOnboarding?.stripeAccountOnboardingLink ?? ''}
                variant="outlined"
                css={css`
                  margin-left: auto;
                  flex: 0 0 auto;
                `}
                disabled={!stripeOnboarding?.stripeAccountOnboardingLink}
              >
                Finish Onboarding
              </Button>
            </>
          ) : (
            <>
              <Typography>
                Your account is being reviewed. Approval can take 1-3 business
                days. Once approved, we will email you with instructions on how
                to finish setting up your account. Until then, app functionality
                will be limited. If you have any questions or concerns, please
                reach out to us at{' '}
                <Link href="mailto:support@benjicard.com">
                  support@benjicard.com
                </Link>
                .
              </Typography>
            </>
          )}
        </AsynchronouslyLoadedSection>
      </Box>
    </Alert>
  );
};
