import { css } from '@emotion/react';
import { Chip } from '@mui/material';
import { startCase, get } from 'lodash';

import { PayoutStatusEnum, TopupStatusEnum } from 'src/types/funds-transfers';
import { UserStatus } from 'src/types/user';

type StatusType =
  | 'balanceTransfer'
  | 'card'
  | 'cardholder'
  | 'dispute'
  | 'transaction'
  | 'user'
  | 'group';

const statusLabelOverrides: Partial<
  Record<StatusType, Record<string, string>>
> = {
  transaction: {
    dispute_won: 'Dispute Credit',
  },
  user: {
    disabled: 'Inactive',
    [UserStatus.PENDING_CLERK_INVITATION]: 'Pending',
  },
  balanceTransfer: {
    // Topups
    [TopupStatusEnum.REVERSED]: 'Reversed',
    [TopupStatusEnum.SUCCEEDED]: 'Completed',
    // Payouts
    [PayoutStatusEnum.PAID]: 'Completed',
    [PayoutStatusEnum.IN_TRANSIT]: 'Pending',
    // Shared - Topus & Payouts
    [TopupStatusEnum.PENDING]: 'Pending',
    [TopupStatusEnum.CANCELED]: 'Canceled',
    [TopupStatusEnum.FAILED]: 'Failed',
  },
};
const getStatusLabel = ({
  type,
  status,
}: {
  type: StatusType;
  status: string;
}) => get(statusLabelOverrides, [type, status], startCase(status));

export const StatusChip = ({
  status,
  type,
  className,
}: {
  status: string;
  type: StatusType;
  className?: string;
}) => {
  if (type === 'balanceTransfer') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({
          status: status,
          type,
        })}
        variant="outlined"
        size="small"
        css={css`
          ${['succeeded', 'paid'].includes(status ?? '')
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'card') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'canceled'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'cardholder') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'dispute') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'expired'
            ? css`
                color: #02a0fc;
                border-color: #02a0fc;
              `
            : status === 'disputed' || status === 'lost'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === 'submitted'
            ? css`
                color: #9747ff;
                border-color: #9747ff;
              `
            : status === 'won'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'transaction') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'approved'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'declined'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === 'reversed'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : status === 'disputed'
            ? css`
                color: #02a0fc;
                border-color: #02a0fc;
              `
            : status === 'refunded' || status === 'dispute_won'
            ? css`
                color: #4339f2;
                border-color: #4339f2;
              `
            : status === 'pending'
            ? css`
                color: #b0b0b0;
                border-color: #b0b0b0;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'user') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'disabled'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else if (type === 'group') {
    return (
      <Chip
        className={className}
        label={getStatusLabel({ status, type })}
        variant="outlined"
        size="small"
        css={css`
          ${status === 'active'
            ? css`
                color: #50c034;
                border-color: #50c034;
              `
            : status === 'inactive'
            ? css`
                color: #fd5045;
                border-color: #fd5045;
              `
            : css`
                color: #888888;
                border-color: #888888;
              `}
        `}
      />
    );
  } else return null;
};
