import { lowerCase } from 'lodash';

import type { GroupCleaned, GroupRaw } from 'src/types/groups';

export const cleanGroups = (rawGroups: GroupRaw[]) =>
  rawGroups.reduce((acc, group) => {
    const status = lowerCase(group.status);
    return [
      ...acc,
      {
        id: group.id,
        name: group.name,
        status: status,
        balanceAllocatedCents: group.balance_allocated_cents,
        balanceAvailableCents: group.balance_available_cents,
        balanceCurrentCents: group.balance_current_cents,
        balanceUnallocatedCents: group.balance_unallocated_cents,
        balancePercentAllocated: group.balance_allocated_percent,
      } as GroupCleaned,
    ];
  }, [] as GroupCleaned[]);
