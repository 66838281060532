import type { Address } from './address';
import type { GroupStatus } from './groups';

export enum CardholderType {
  MEMBER = 'member',
  INTERNAL = 'internal',
}

export enum CardholderStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface CardholderRaw {
  id: string;
  stripe_cardholder_id: string;
  billing: {
    address: Address;
  };
  company: string | null;
  created: string;
  email: string;
  metadata: {
    type: CardholderType;
  };
  name: string;
  phone_number: string;
  requirements: {
    past_due: [];
    disabled_reason: null;
  };
  status: CardholderStatus;
  type: string;
  individual: {
    dob: {
      day: number;
      year: number;
      month: number;
    };
    last_name: string;
    first_name: string;
    card_issuing: {
      user_terms_acceptance: {
        ip: string | null;
        date: number;
        user_agent: string | null;
      };
    };
    verification: {
      document: {
        back: string | null;
        front: string | null;
      };
    };
  };
  aggregate_remaining_spend_limit_cents: number;
  aggregate_spend_limit_cents: number;
  aggregate_spent_cents: number;
  aggregate_spent_pending_cents: number;
  total_cards: number;
  total_transactions: number;
  group_id?: string;
  group?: {
    id: string;
    name: string;
  };
}

export interface CardholderCleaned {
  id: CardholderRaw['id'];
  stripeId: CardholderRaw['stripe_cardholder_id'];
  billingAddress: CardholderRaw['billing']['address'];
  createdAt: CardholderRaw['created'];
  name: {
    first: CardholderRaw['individual']['first_name'];
    last: CardholderRaw['individual']['last_name'];
    full: CardholderRaw['name'];
  };
  status: CardholderRaw['status'];
  isActive: boolean;
  email: CardholderRaw['email'];
  phoneNumber: CardholderRaw['phone_number'];
  dob: string;
  cardholderType: CardholderRaw['metadata']['type'];
  aggregateRemainingSpendLimitCents: CardholderRaw['aggregate_remaining_spend_limit_cents'];
  aggregateSpendLimitCents: CardholderRaw['aggregate_remaining_spend_limit_cents'];
  aggregateSpentCents: CardholderRaw['aggregate_remaining_spend_limit_cents'];
  aggregateSpentPendingCents: CardholderRaw['aggregate_remaining_spend_limit_cents'];
  aggregateSpentTotalCents: number;

  transactionsCount: CardholderRaw['total_transactions'];
  cardsCount: CardholderRaw['total_cards'];
  group?: CardholderRaw['group'];
}

export interface CardholderDetailsRaw {
  id: string;
  stripe_cardholder_id: string;
  billing: {
    address: Address;
  };
  company: string | null;
  created: string;
  email: string;
  metadata: {
    type: CardholderType;
  };
  name: string;
  phone_number: string;
  requirements: {
    past_due: [];
    disabled_reason: null;
  };

  status: CardholderStatus;
  type: string;
  individual: {
    dob: {
      day: number;
      year: number;
      month: number;
    };
    last_name: string;
    first_name: string;
    card_issuing: {
      user_terms_acceptance: {
        ip: string | null;
        date: number;
        user_agent: string | null;
      };
    };
    verification: {
      document: {
        back: string | null;
        front: string | null;
      };
    };
  };
  aggregate_remaining_spend_limit_cents: number;
  aggregate_spend_limit_cents: number;
  aggregate_spent_cents: number;
  aggregate_spent_pending_cents: number;
  total_cards: number;
  total_transactions: number;
  group_id?: string;
  group?: {
    id: string;
    name: string;
    status: GroupStatus;
  };
}

export interface CardholderDetailsCleaned {
  id: CardholderDetailsRaw['id'];
  stripeId: CardholderDetailsRaw['stripe_cardholder_id'];
  billingAddress: CardholderDetailsRaw['billing']['address'];
  createdAt: CardholderDetailsRaw['created'];
  prettyCreatedAtDate: string;
  name: {
    first: CardholderDetailsRaw['individual']['first_name'];
    last: CardholderDetailsRaw['individual']['last_name'];
    full: CardholderDetailsRaw['name'];
  };
  status: CardholderDetailsRaw['status'];
  isActive: boolean;
  email: CardholderDetailsRaw['email'];
  phoneNumber: CardholderDetailsRaw['phone_number'];
  dob: string;
  cardholderType: CardholderDetailsRaw['metadata']['type'];

  aggregateRemainingSpendLimitCents: CardholderDetailsRaw['aggregate_remaining_spend_limit_cents'];
  aggregateSpendLimitCents: CardholderDetailsRaw['aggregate_spend_limit_cents'];
  aggregateSpentCents: CardholderDetailsRaw['aggregate_spent_cents'];
  aggregateSpentPendingCents: CardholderDetailsRaw['aggregate_spent_pending_cents'];
  aggregateSpentTotalCents: number;
  transactionsCount: CardholderDetailsRaw['total_transactions'];

  cardsCount: CardholderDetailsRaw['total_cards'];
  group?: CardholderDetailsRaw['group'];
}
