import { css } from '@emotion/react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Box,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Popover,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { isEqual, merge, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import {
  AsynchronouslyLoadedSection,
  FormFieldSwitch,
  SlideLeftTransition,
  StatusChip,
} from 'src/components';
import {
  TrackContext,
  TrackingContextProvider,
  TrackType,
  useModal,
  useToast,
  useTrack,
} from 'src/context';
import {
  useAppRoutes,
  useGetFeatures,
  useGetUserDetails,
  usePrevious,
} from 'src/hooks';
import { useGetSubUser } from 'src/hooks/useGetSubUser';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { useGetUserPrivilegesLabels, UserStatus } from 'src/types/user';
import { searchParamsToObject } from 'src/utils';
import { splitArray } from 'src/utils/split-array';

import { SubUserIsActiveForm } from './SubUserIsActiveForm';
import { useDeleteUser } from './useDeleteUser';
import { useUpdateUser } from './useUpdateUser';

import type { AxiosError } from 'axios';
import type { FieldPath } from 'react-hook-form';

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;
interface FormFields {
  userPrivileges: Record<Privilege, boolean>;
  isActive: boolean;
}

const SUB_USER_DETAILS_MODAL_ID = 'sub-user-details-modal';

const privilegeBaseCss = css`
  justify-content: space-between;
  max-width: 176px;
`;

export const useSubUserDetailsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: SUB_USER_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/users', {
        queryParams: omit(currentSearchParams, ['selectedSubUserId']),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'User Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id?: string) => {
      if (id) {
        const isSamePage = pathname === getRoutePathName('/secure/users');
        navigate('/secure/users', {
          queryParams: {
            ...(isSamePage && currentSearchParams),
            selectedSubUserId: id,
          },
          preventScrollReset: isSamePage,
        });
      }
      modal.openModal();
    },
  };
};

const SubUserDetailsTrackingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const selectedSubUserId = searchParams.get('selectedSubUserId');
  const { data: selectedUser } = useGetSubUser({ id: selectedSubUserId ?? '' });

  return (
    <TrackingContextProvider
      trackingProps={{
        ...(!!selectedUser && {
          subUserId: selectedUser.id,
          subUserEmail: selectedUser.email,
          subUserFirstName: selectedUser.name.first,
          subUserLastName: selectedUser.name.last,
          subUserCreatedAt: new Date(selectedUser.createdAt).toISOString(),
          subUserStatus: selectedUser.status,
          privileges: selectedUser.privileges,
        }),
      }}
      contextName={TrackContext['sub-user']}
    >
      {children}
    </TrackingContextProvider>
  );
};

const SubUserDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { data: user } = useGetUserDetails();
  const { track } = useTrack();
  const [searchParams] = useSearchParams();
  const selectedSubUserId = searchParams.get('selectedSubUserId');
  const { data: selectedUser, isLoading } = useGetSubUser({
    id: selectedSubUserId ?? '',
  });
  const { userPrivilegesLabel } = useGetUserPrivilegesLabels();

  const isCurrentUser = user?.userDetails.id === selectedSubUserId;

  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({});
  const hasUnsavedChanges = Object.values(unsavedChanges).some(
    ({ hasUnsavedChanges }) => hasUnsavedChanges,
  );

  const { setToast } = useToast();

  const defaultValues = useMemo(
    () => ({
      userPrivileges: merge(
        Object.fromEntries(Object.keys(Privilege).map((key) => [key, false])),
        selectedUser?.privileges,
      ),
    }),
    [selectedUser?.privileges],
  );

  const useFormMethods = useForm<FormFields>({
    defaultValues,
  });
  const { handleSubmit, control, watch, reset, formState } = useFormMethods;
  const { isSubmitting } = formState;

  const handleReset = useCallback(() => {
    reset(defaultValues);
    setUnsavedChanges({});
  }, [defaultValues, reset]);

  useEffect(() => {
    handleReset();
  }, [handleReset, selectedUser?.id]);

  const formValues = watch();

  const previousUserPrivilegesLabels = usePrevious(userPrivilegesLabel);
  useEffect(() => {
    const isSame = isEqual(userPrivilegesLabel, previousUserPrivilegesLabels);
    if (!isSame) {
      setUnsavedChanges(
        (Object.keys(Privilege) as Array<keyof typeof Privilege>).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              hasUnsavedChanges:
                formValues.userPrivileges[key] !==
                defaultValues.userPrivileges[key],
              label: userPrivilegesLabel[key],
            },
          }),
          {},
        ),
      );
    }
  }, [
    previousUserPrivilegesLabels,
    userPrivilegesLabel,
    defaultValues.userPrivileges,
    defaultValues.userPrivileges.transactions,
    defaultValues.userPrivileges.cards,
    defaultValues.userPrivileges.card_details,
    defaultValues.userPrivileges.issue_card,
    defaultValues.userPrivileges.send_card,
    defaultValues.userPrivileges.balance,
    defaultValues.userPrivileges.fund_balance,
    defaultValues.userPrivileges.dispute,
    defaultValues.userPrivileges.create_dispute,
    defaultValues.userPrivileges.subuser_admin,
    defaultValues.userPrivileges.company_details,
    defaultValues.userPrivileges.create_edit_groups,
    formValues.userPrivileges,
    formValues.userPrivileges.transactions,
    formValues.userPrivileges.cards,
    formValues.userPrivileges.card_details,
    formValues.userPrivileges.issue_card,
    formValues.userPrivileges.send_card,
    formValues.userPrivileges.balance,
    formValues.userPrivileges.fund_balance,
    formValues.userPrivileges.dispute,
    formValues.userPrivileges.create_dispute,
    formValues.userPrivileges.subuser_admin,
    formValues.userPrivileges.company_details,
    formValues.userPrivileges.create_edit_groups,
  ]);

  const { mutateAsync: updateUser } = useUpdateUser();

  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState(false);

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      if (showUnsavedChangesWarning) {
        setUnsavedChanges({});
        setShowUnsavedChangesWarning(false);
        closeModal();
      } else {
        setShowUnsavedChangesWarning(true);
      }
    } else {
      setUnsavedChanges({});
      setShowUnsavedChangesWarning(false);
      closeModal();
    }
  };

  const onSubmit = async (values: FormFields) => {
    await updateUser(
      {
        userId: selectedUser?.id ?? '',
        privileges: values.userPrivileges,
      },
      {
        onSuccess: () => {
          setToast({
            message: 'User Updated Successfully',
            severity: 'success',
          });
          track({
            label: 'Edited sub user',
            type: TrackType.effect,
            isSuccessful: true,
            subUserId: selectedUser?.id,
            subUserEmail: selectedUser?.email,
            subUserFirstName: selectedUser?.name.first,
            subUserLastName: selectedUser?.name.last,
            subUserCreatedAt: selectedUser?.createdAt
              ? new Date(selectedUser.createdAt).toISOString()
              : null,
            subUserStatus: selectedUser?.status,
            privileges: values.userPrivileges,
          });
        },
        onError: (error) => {
          const errorObject = error as AxiosError;
          const errorMessage =
            typeof errorObject?.response?.data === 'string' &&
            errorObject?.response?.data;
          setToast({
            message: `Error Updating User${
              errorMessage ? `: ${errorMessage}` : ''
            }`,
            severity: 'error',
          });
          track({
            label: 'Edited sub user',
            type: TrackType.effect,
            isSuccessful: false,
            subUserId: selectedUser?.id,
            subUserEmail: selectedUser?.email,
            subUserFirstName: selectedUser?.name.first,
            subUserLastName: selectedUser?.name.last,
            subUserCreatedAt: selectedUser?.createdAt
              ? new Date(selectedUser.createdAt).toISOString()
              : null,
            subUserStatus: selectedUser?.status,
            privileges: values.userPrivileges,
          });
        },
      },
    );
  };

  const { pathname, getRoutePathName } = useAppRoutes();
  const { isOpen, closeModal, openModal } = useSubUserDetailsModal({
    onClose: () => {
      handleReset();
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'User Details',
        ...(hasUnsavedChanges && {
          unsavedChanges: Object.entries(unsavedChanges)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not using the key to filter
            .filter(([_key, { hasUnsavedChanges }]) => hasUnsavedChanges)
            .map(([label]) => label),
        }),
      });
    },
  });
  useEffect(() => {
    if (!isOpen && selectedSubUserId) {
      openModal(selectedSubUserId);
    }
  }, [isOpen, openModal, selectedSubUserId]);

  const isSamePage = pathname === getRoutePathName('/secure/transactions');
  useEffect(() => {
    if (isOpen && !selectedSubUserId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedSubUserId, isSamePage]);

  const { id, name, email, isActive } = selectedUser ?? {};

  const { features } = useGetFeatures();

  const { mutate: delteUser, isLoading: isDeletingUser } = useDeleteUser();
  const [isDangerZoneOpen, setIsDangerZoneOpen] = useState(false);
  const handleDeleteUser = () => {
    delteUser(
      {
        userId: id ?? '',
      },
      {
        onSuccess: () => {
          setIsDangerZoneOpen(false);
          setUnsavedChanges({});
          setShowUnsavedChangesWarning(false);
          closeModal();
          setToast({
            message: `User deleted successfully.`,
            severity: 'success',
          });
        },
        onError: () => {
          setToast({
            message: 'Unable to delete user. Please try again later.',
            severity: 'error',
          });
        },
      },
    );
  };

  const isPrivilegeSwitchDisabled =
    (!!selectedUser && !isActive) || isSubmitting || isCurrentUser;

  const fields = [
    {
      name: `userPrivileges.${Privilege.transactions}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.transactions],
      defaultValue: defaultValues.userPrivileges[Privilege.transactions],
    },
    {
      name: `userPrivileges.${Privilege.cards}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.cards],
      defaultValue: defaultValues.userPrivileges[Privilege.cards],
    },
    ...(features?.groups
      ? [
          {
            name: `userPrivileges.${Privilege.create_edit_groups}` as FieldPath<
              Omit<FormFields, 'isActive'>
            >,
            label: userPrivilegesLabel[Privilege.create_edit_groups],
            defaultValue:
              defaultValues.userPrivileges[Privilege.create_edit_groups],
          },
        ]
      : []),
    {
      name: `userPrivileges.${Privilege.card_details}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.card_details],
      defaultValue: defaultValues.userPrivileges[Privilege.card_details],
    },
    {
      name: `userPrivileges.${Privilege.issue_card}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.issue_card],
      defaultValue: defaultValues.userPrivileges[Privilege.issue_card],
    },
    ...(features?.sendCardsViaEmail
      ? [
          {
            name: `userPrivileges.${Privilege.send_card}` as FieldPath<
              Omit<FormFields, 'isActive'>
            >,
            label: userPrivilegesLabel[Privilege.send_card],
            defaultValue: defaultValues.userPrivileges[Privilege.send_card],
          },
        ]
      : []),
    {
      name: `userPrivileges.${Privilege.balance}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.balance],
      defaultValue: defaultValues.userPrivileges[Privilege.balance],
    },
    {
      name: `userPrivileges.${Privilege.fund_balance}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.fund_balance],
      defaultValue: defaultValues.userPrivileges[Privilege.fund_balance],
    },
    {
      name: `userPrivileges.${Privilege.dispute}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.dispute],
      defaultValue: defaultValues.userPrivileges[Privilege.dispute],
    },
    {
      name: `userPrivileges.${Privilege.create_dispute}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.create_dispute],
      defaultValue: defaultValues.userPrivileges[Privilege.create_dispute],
    },
    {
      name: `userPrivileges.${Privilege.subuser_admin}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.subuser_admin],
      defaultValue: defaultValues.userPrivileges[Privilege.subuser_admin],
    },
    {
      name: `userPrivileges.${Privilege.company_details}` as FieldPath<
        Omit<FormFields, 'isActive'>
      >,
      label: userPrivilegesLabel[Privilege.company_details],
      defaultValue: defaultValues.userPrivileges[Privilege.company_details],
    },
  ];
  const [firstHalfOfFields, secondHalfOfFields] = splitArray(fields);

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Edit User
        </Typography>
        <SubUserIsActiveForm
          isUserActive={isActive}
          userId={id ?? ''}
          disabled={
            isCurrentUser ||
            isSubmitting ||
            selectedUser?.status === UserStatus.PENDING_CLERK_INVITATION
          }
        />
      </DialogTitle>
      <AsynchronouslyLoadedSection
        isLoading={!!selectedSubUserId && isLoading}
        css={css`
          min-height: 600px;
        `}
      >
        <FormProvider {...useFormMethods}>
          <DialogContent
            css={css`
              display: flex;
              flex-direction: column;
              padding: 10px 8px !important;
            `}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              css={css`
                display: flex;
                flex-wrap: wrap;
                gap: 12px 34px;
                align-items: flex-start;
                align-content: flex-start;
                flex-grow: 1;
                padding-bottom: 24px;
              `}
            >
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  gap: 16px;
                  padding-bottom: 12px;
                `}
              >
                <Box
                  css={css`
                    flex: 1 1 120px;
                  `}
                >
                  <Typography
                    variant="body1"
                    css={css`
                      font-size: 15px;
                      font-weight: 700;
                      padding-bottom: 4px;
                    `}
                  >
                    {name?.full}
                  </Typography>
                  <Typography
                    variant="body1"
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {email}
                  </Typography>
                </Box>

                <Box
                  css={css`
                    flex: 0 0 100px;
                  `}
                >
                  <Typography
                    variant="body1"
                    css={css`
                      font-size: 10px;
                      padding-bottom: 4px;
                      color: ${COLOR_PALETTE.lightTextOnLight};
                    `}
                  >
                    Created On
                  </Typography>
                  <Typography
                    variant="body1"
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    {selectedUser?.prettyCreatedAtDate}
                  </Typography>
                </Box>
                <Box
                  css={css`
                    flex: 0 0 100%;
                  `}
                >
                  <Typography
                    variant="body1"
                    css={css`
                      font-size: 10px;
                      padding-bottom: 4px;
                      color: ${COLOR_PALETTE.lightTextOnLight};
                    `}
                  >
                    Status
                  </Typography>
                  <Typography
                    variant="body1"
                    css={css`
                      font-size: 12px;
                    `}
                  >
                    <Box
                      css={css`
                        display: flex;
                      `}
                    >
                      <StatusChip
                        status={selectedUser?.status ?? ''}
                        type="user"
                      />
                    </Box>
                  </Typography>
                </Box>
              </Box>
              {isCurrentUser && (
                <Alert
                  severity="warning"
                  css={css`
                    flex: 1 1 100%;
                  `}
                >
                  You cannot edit your own user.
                </Alert>
              )}
              {!!selectedUser && !isActive && (
                <Alert
                  severity="warning"
                  css={css`
                    width: 100%;
                  `}
                >
                  Inactive users cannot be edited
                </Alert>
              )}
              <Typography
                variant="body1"
                css={css`
                  flex: 1 1 100%;
                  font-size: 12px;
                  font-weight: 600;
                  padding-bottom: 8px;
                  border-bottom: 1px dashed ${COLOR_PALETTE.border};
                `}
              >
                Privileges
              </Typography>
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: 25px 12px;
                  justify-content: space-between;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    gap: 25px 0;
                    flex-grow: 1;
                    label {
                      max-width: none;
                      padding-right: 8px;
                    }
                  `}
                >
                  {firstHalfOfFields?.map(({ name, label, defaultValue }) => (
                    <FormFieldSwitch
                      key={name}
                      disabled={isPrivilegeSwitchDisabled}
                      control={control}
                      name={name}
                      label={label}
                      defaultValue={defaultValue}
                      css={privilegeBaseCss}
                    />
                  ))}
                </Box>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    gap: 25px 0;
                    flex-grow: 1;
                    label {
                      max-width: none;
                      padding-right: 8px;
                    }
                  `}
                >
                  {secondHalfOfFields?.map(({ name, label, defaultValue }) => (
                    <FormFieldSwitch
                      key={name}
                      disabled={isPrivilegeSwitchDisabled}
                      control={control}
                      name={name}
                      label={label}
                      defaultValue={defaultValue}
                      css={privilegeBaseCss}
                    />
                  ))}
                </Box>
              </Box>
              {!!formValues.userPrivileges.create_dispute &&
                !formValues.userPrivileges.dispute && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.create_dispute]}, you must
                    also give them access to{' '}
                    {userPrivilegesLabel[Privilege.dispute]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.fund_balance &&
                !formValues.userPrivileges.balance && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.fund_balance]}, you must also
                    give them access to {userPrivilegesLabel[Privilege.balance]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.card_details &&
                !formValues.userPrivileges.cards && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.card_details]}, you must also
                    give them access to {userPrivilegesLabel[Privilege.cards]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.send_card &&
                !formValues.userPrivileges.card_details && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to {userPrivilegesLabel[Privilege.send_card]}
                    , you must also give them access to{' '}
                    {userPrivilegesLabel[Privilege.card_details]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.issue_card &&
                !formValues.userPrivileges.card_details && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.issue_card]}, you must also
                    give them access to{' '}
                    {userPrivilegesLabel[Privilege.card_details]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.cards &&
                !formValues.userPrivileges.transactions && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to {userPrivilegesLabel[Privilege.cards]}{' '}
                    they must also have access to{' '}
                    {userPrivilegesLabel[Privilege.transactions]}
                  </Alert>
                )}
              {!!formValues.userPrivileges.create_edit_groups &&
                !formValues.userPrivileges.cards && (
                  <Alert
                    severity="warning"
                    css={css`
                      flex: 1 1 100%;
                    `}
                  >
                    To have access to{' '}
                    {userPrivilegesLabel[Privilege.create_edit_groups]} they
                    must also have access to{' '}
                    {userPrivilegesLabel[Privilege.cards]}
                  </Alert>
                )}
              {hasUnsavedChanges && showUnsavedChangesWarning && (
                <Alert
                  severity="warning"
                  css={css`
                    flex: 1 1 100%;
                  `}
                >
                  You have unsaved changes:{' '}
                  {Object.values(unsavedChanges)
                    .filter(({ hasUnsavedChanges }) => hasUnsavedChanges)
                    .map(({ label }) => label)
                    .join(', ')}
                  . Are you sure you want to leave? Click again to leave without
                  saving.
                </Alert>
              )}
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-end;
                  flex: 1 1 100%;
                  padding-top: 8px;
                `}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    (!!selectedUser && !isActive) ||
                    isSubmitting ||
                    isCurrentUser ||
                    (!!formValues.userPrivileges.create_dispute &&
                      !formValues.userPrivileges.dispute) ||
                    (!!formValues.userPrivileges.fund_balance &&
                      !formValues.userPrivileges.balance) ||
                    (!!formValues.userPrivileges.card_details &&
                      !formValues.userPrivileges.cards) ||
                    (!!formValues.userPrivileges.send_card &&
                      !formValues.userPrivileges.card_details) ||
                    (!!formValues.userPrivileges.issue_card &&
                      !formValues.userPrivileges.card_details) ||
                    (!!formValues.userPrivileges.cards &&
                      !formValues.userPrivileges.transactions) ||
                    (!!formValues.userPrivileges.create_edit_groups &&
                      !formValues.userPrivileges.cards)
                  }
                  onClick={() => {
                    track({
                      label: 'Submit edit sub user',
                      type: TrackType.action,
                      actionType: 'submit',
                      subUserEmail: selectedUser?.email,
                      subUserFirstName: selectedUser?.name.first,
                      subUserLastName: selectedUser?.name.last,
                      subUserCreatedAt: selectedUser?.createdAt
                        ? new Date(selectedUser.createdAt).toISOString()
                        : null,
                      subUserStatus: selectedUser?.status,
                      privileges: formValues.userPrivileges,
                    });
                  }}
                >
                  Update User
                </Button>
              </Box>
            </Box>
            {!isCurrentUser && (
              <Accordion
                elevation={0}
                expanded={isDangerZoneOpen}
                onChange={() => setIsDangerZoneOpen(!isDangerZoneOpen)}
                css={css`
                  background-color: ${COLOR_PALETTE.dangerAccordionBackground};
                  border-radius: 8px;
                  border: none;
                `}
              >
                <AccordionSummary
                  css={css`
                    padding: 0;
                    &,
                    .Mui-expanded {
                      margin: 12px 14px;
                      min-height: initial !important;
                      .MuiAccordionSummary-content {
                        margin: 0;
                      }
                      .MuiAccordionSummary-expandIconWrapper {
                        margin: 0;
                      }
                    }
                    p {
                      color: ${COLOR_PALETTE.dangerAccordionText};
                      font-weight: 500;
                      margin: 0;
                    }
                    svg {
                      color: ${COLOR_PALETTE.textOnLight};
                    }
                  `}
                  expandIcon={<ExpandMoreIcon color="error" />}
                >
                  <Typography color="error">Delete User</Typography>
                </AccordionSummary>
                <AccordionDetails
                  css={css`
                    padding: 0 16px 16px;
                  `}
                >
                  <Typography
                    css={css`
                      font-size: 12px;
                      padding-bottom: 20px;
                    `}
                  >
                    Are you sure you want to delete this user? This action
                    cannot be undone.
                  </Typography>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteUser}
                    disabled={isDeletingUser}
                    css={css`
                      margin-left: auto;
                    `}
                  >
                    Delete User Permanently
                  </Button>
                </AccordionDetails>
              </Accordion>
            )}
          </DialogContent>
        </FormProvider>
      </AsynchronouslyLoadedSection>
    </Popover>
  );
};

export const SubUserDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <SubUserDetailsTrackingContextProvider>
      <SubUserDetailsModalBase anchorEl={anchorEl} />
    </SubUserDetailsTrackingContextProvider>
  );
};
