import { useQuery } from '@tanstack/react-query';

import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserDetails } from './useGetUserDetails';

interface GetContinueStripeRegistrationLinkReturn {
  stripeAccountOnboardingLink: string;
}

export const useGetContinueStripeRegistrationLink = () => {
  const { axios, generateSecureHeaders } = useAxios();
  const { data: userData } = useGetUserDetails();
  const { isOnboarding } = userData ?? {};

  const getContinueStripeRegistrationLink = async () => {
    const { data } = await axios.get<GetContinueStripeRegistrationLinkReturn>(
      SERVICE_BENJI_CARD_URLS.USER_GET_CONTINUE_STRIPE_REGISTRATION_LINK,
      { headers: await generateSecureHeaders() },
    );
    return data;
  };

  return useQuery({
    queryFn: () => getContinueStripeRegistrationLink(),
    queryKey: queryKeys.account.stripeOnboardingLink(),
    enabled: isOnboarding,
    staleTime: Infinity,
  });
};
