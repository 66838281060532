import { css } from '@emotion/react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  InfoPopover,
} from 'src/components';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import {
  useGetUserPrivileges,
  useGetAccountOverview,
  useGetAccountMonthOverview,
  useAppRoutes,
  useGetUserDetails,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { monthNames, today } from 'src/utils';

import { AccountBalancesOverviewCard } from './AccountBalancesOverviewCard';
import { CardIcon } from './CardIcon';
import { FundsAssignmentOverviewCard } from './FundsAssignmentOverviewCard';
import { FundsTransfersOverviewCard } from './FundsTransfersOverviewCard';
import { GroupsOverviewCard } from './GroupsOverviewCard';
import { OnboardingCard } from './OnboardingCard';
import { TransactionsOverviewCard } from './TransactionsOverviewCard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

export const DashboardPage = () => {
  const { privileges, isLoading: areUserPrivilegesLoading } =
    useGetUserPrivileges();

  const { data: accountOverview, isLoading: isBalanceLoading } =
    useGetAccountOverview();

  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const { data: monthOverview, isLoading: isMonthOverviewLoading } =
    useGetAccountMonthOverview({ year, month });

  const { navigate } = useAppRoutes();

  const {
    accountBalance,
    cardsWithRemainingSpendLimit,
    staleCards,
    countOfOverAllocatedGroups,
  } = accountOverview ?? {};

  const isDataLoading = isBalanceLoading || isMonthOverviewLoading;

  const isPageLoading = areUserPrivilegesLoading || isUserLoading;

  const hasAllocatedMoreThanAccountBalance =
    (accountBalance?.allocatedAvailableCents ?? 0) >
    (accountBalance?.availableCents ?? 0);

  return (
    <Elements stripe={stripePromise}>
      <Box
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
        `}
      >
        <AsynchronouslyLoadedSection isLoading={isPageLoading}>
          {!!privileges?.cards &&
            !!privileges?.transactions &&
            !!privileges?.balance && (
              <>
                <OnboardingCard />

                <AccountBalancesOverviewCard />
                <Card
                  css={css`
                    flex: 1 1 400px;
                  `}
                >
                  <CardContent
                    css={css`
                      display: flex;
                      flex-wrap: nowrap;
                      gap: 16px;
                      justify-content: space-between;
                      align-items: center;
                      height: 100%;
                      padding: 8px 12px;
                    `}
                  >
                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        gap: 16px;
                        justify-content: space-between;
                        height: 100%;
                        flex: 1 1 200px;
                      `}
                    >
                      <AsynchronouslyLoadedSection isLoading={isDataLoading}>
                        <DataItemDisplay
                          css={css`
                            width: 100%;
                            cursor: pointer;
                            padding: 8px;
                            &:hover {
                              background-color: ${COLOR_PALETTE.dataItemLinkHover};
                              border-radius: 8px;
                            }
                          `}
                          onClick={() => {
                            navigate('/secure/cards', {
                              queryParams: {
                                filters: JSON.stringify({
                                  hasUnspentLimits: true,
                                }),
                              },
                            });
                          }}
                          label="Cards (Remaining Spend Limits)"
                          value={cardsWithRemainingSpendLimit}
                        />
                        <DataItemDisplay
                          css={css`
                            width: 100%;
                            cursor: pointer;
                            padding: 8px;
                            &:hover {
                              background-color: ${COLOR_PALETTE.dataItemLinkHover};
                              border-radius: 8px;
                            }
                          `}
                          onClick={() => {
                            navigate('/secure/cards', {
                              queryParams: {
                                filters: JSON.stringify({
                                  isStale: true,
                                }),
                              },
                            });
                          }}
                          label={
                            <>
                              Cards (Stale)
                              <InfoPopover
                                contents="Active, single-use cards that have not been used in the last 30 days."
                                name="Stale Cards"
                              />
                            </>
                          }
                          value={staleCards}
                        />
                      </AsynchronouslyLoadedSection>
                    </Box>
                    <Box
                      css={css`
                        flex: 1 1 50px;
                        max-width: 100px;
                      `}
                    >
                      <CardIcon
                        css={css`
                          width: 100%;
                          height: 100%;
                        `}
                      />
                    </Box>
                  </CardContent>
                </Card>
                {hasGroupsLedgerEnabled ? (
                  <>
                    {!!countOfOverAllocatedGroups && (
                      <Alert severity="error">
                        <Box
                          css={css`
                            display: flex;
                            gap: 40px;
                            align-items: center;
                          `}
                        >
                          <Typography>
                            You have {countOfOverAllocatedGroups} group
                            {countOfOverAllocatedGroups > 1 ? 's' : ''} with
                            more funds allocated via your card spend limits than{' '}
                            {countOfOverAllocatedGroups > 1 ? 'it' : 'they'}
                            currently have available in their group balance
                            {countOfOverAllocatedGroups > 1 ? 's' : ''}.
                            Depending on the scheduling of your transactions,
                            this may result in declined transactions. Assign
                            more funds to{' '}
                            {countOfOverAllocatedGroups > 1
                              ? 'these groups'
                              : 'this group'}{' '}
                            to avoid declined transactions.
                          </Typography>
                          {privileges.fund_balance && (
                            <Button
                              onClick={() =>
                                navigate('/secure/groups', {
                                  queryParams: {
                                    filters: JSON.stringify({
                                      isOverAllocated: true,
                                    }),
                                  },
                                })
                              }
                              variant="outlined"
                              color="error"
                              css={css`
                                flex: 0 0 auto;
                                margin: -4px 0 -4px auto;
                              `}
                            >
                              View Over Allocated Group
                              {countOfOverAllocatedGroups > 1 ? 's' : ''}
                            </Button>
                          )}
                        </Box>
                      </Alert>
                    )}
                  </>
                ) : (
                  <>
                    {hasAllocatedMoreThanAccountBalance && (
                      <Alert severity="error">
                        <Box
                          css={css`
                            display: flex;
                            gap: 40px;
                            align-items: center;
                          `}
                        >
                          <Typography>
                            You have allocated more funds via your card spend
                            limits than you currently have available in your
                            account balance. Depending on the scheduling of your
                            transactions, this may result in declined
                            transactions.
                          </Typography>
                          {privileges.fund_balance && (
                            <Button
                              onClick={() =>
                                navigate(
                                  '/secure/funds-management/new-transfer',
                                  {
                                    queryParams: {
                                      'move-funds-tab': '0',
                                    },
                                  },
                                )
                              }
                              variant="outlined"
                              color="error"
                              css={css`
                                flex: 0 0 auto;
                                margin: -4px 0 -4px auto;
                              `}
                            >
                              Fund Balance
                            </Button>
                          )}
                        </Box>
                      </Alert>
                    )}
                  </>
                )}
                {hasGroupsLedgerEnabled &&
                  (accountBalance?.unassignedAvailableCents ?? 0) < 0 && (
                    <Alert
                      severity="error"
                      css={css`
                        width: 100%;
                        .MuiAlert-message {
                          flex: 1 1 auto;
                        }
                      `}
                    >
                      <Box
                        css={css`
                          display: flex;
                          gap: 40px;
                          align-items: flex-start;
                        `}
                      >
                        <Typography>
                          Funds have been paid out from your account balance
                          before being unassigned from a group. This may result
                          in declined transactions. Please unassign funds from
                          groups to avoid declined transactions. The amount that
                          needs to be unassigned is{' '}
                          <FancyCurrencyDisplay
                            isPlain
                            amountCents={
                              accountBalance?.unassignedAvailableCents
                            }
                          />
                        </Typography>
                        {privileges.fund_balance && (
                          <Button
                            onClick={() =>
                              navigate(
                                '/secure/funds-management/new-transfer',
                                {
                                  queryParams: {
                                    'move-funds-tab': '2',
                                  },
                                },
                              )
                            }
                            variant="outlined"
                            color="error"
                            css={css`
                              flex: 0 0 auto;
                              margin: -4px 0 -4px auto;
                            `}
                          >
                            Unassign Funds from Groups
                          </Button>
                        )}
                      </Box>
                    </Alert>
                  )}
                {hasGroupsLedgerEnabled &&
                  (accountBalance?.unassignedAvailableCents ?? 0) > 0 && (
                    <Alert
                      severity="warning"
                      css={css`
                        width: 100%;
                        .MuiAlert-message {
                          flex: 1 1 auto;
                        }
                      `}
                    >
                      <Box
                        css={css`
                          display: flex;
                          gap: 40px;
                          align-items: flex-start;
                        `}
                      >
                        <Typography>
                          Your account balance has funds (
                          <FancyCurrencyDisplay
                            isPlain
                            amountCents={
                              accountBalance?.unassignedAvailableCents
                            }
                          />
                          ) that isn{`'`}t assigned to a group yet. Funds cannot
                          be spent on cards until they have been assigned to a
                          group.
                        </Typography>
                        {privileges.fund_balance && (
                          <Button
                            onClick={() =>
                              navigate(
                                '/secure/funds-management/new-transfer',
                                {
                                  queryParams: {
                                    'move-funds-tab': '1',
                                  },
                                },
                              )
                            }
                            variant="outlined"
                            color="warning"
                            css={css`
                              flex: 0 0 auto;
                              margin: -4px 0 -4px auto;
                            `}
                          >
                            Assign Funds to Groups
                          </Button>
                        )}
                      </Box>
                    </Alert>
                  )}
                <Card
                  css={css`
                    flex: 1 1 100%;
                  `}
                >
                  <CardContent
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 16px;
                      justify-content: space-between;
                    `}
                  >
                    <AsynchronouslyLoadedSection isLoading={isDataLoading}>
                      <DataItemDisplay
                        css={css`
                          flex: 0 1 250px;
                        `}
                        label={
                          <>
                            Total Spend ({monthNames[today.getMonth()]})
                            <InfoPopover
                              contents="This is the total amount spent each month on all cards minus any refunds. This number may vary from final totals provided each month and should be treated as an estimate."
                              name="Total Spend"
                            />
                          </>
                        }
                        value={
                          <FancyCurrencyDisplay
                            size="lg"
                            amountCents={
                              (monthOverview?.accumulatedSpendCents ?? 0) * -1
                            }
                          />
                        }
                        isPrivate
                      />
                      <DataItemDisplay
                        css={css`
                          flex: 0 1 250px;
                        `}
                        label={`Total Transactions (${
                          monthNames[today.getMonth()]
                        })`}
                        value={monthOverview?.transactionCount ?? 0}
                      />
                    </AsynchronouslyLoadedSection>
                  </CardContent>
                </Card>
              </>
            )}
          <GroupsOverviewCard />
          <TransactionsOverviewCard />
          <FundsTransfersOverviewCard />
          <FundsAssignmentOverviewCard />
          {!Object.values(privileges ?? {}).some(
            (value) => typeof value === 'boolean' && !!value,
          ) && (
            <Card
              css={css`
                flex: 1 1 100%;
              `}
            >
              <CardContent
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                `}
              >
                <Typography variant="h6">No Permissions</Typography>
                <Typography>
                  You do not have any permissions set. Please contact your
                  administrator to request access.
                </Typography>
              </CardContent>
            </Card>
          )}
        </AsynchronouslyLoadedSection>
      </Box>
    </Elements>
  );
};
