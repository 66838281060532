import { css } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Alert,
  Link,
} from '@mui/material';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DataRow,
  FancyCurrencyDisplay,
  InfoPopover,
  PaginatedTableWrapper,
  SlideLeftTransition,
  StatusChip,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { InlineCreateCardForm } from 'src/components/modals/CardholderDetailsModal/InlineCreateCardForm';
import { ModalTabs } from 'src/components/ModalTabs';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import {
  useAppRoutes,
  useGetFeatures,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { useGetCardholder } from 'src/hooks/useGetCardholder';
import { cleanCards } from 'src/pages/Cards/utils';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { CardTypeLabel } from 'src/types/cards';
import { GroupStatus } from 'src/types/groups';
import {
  SERVICE_BENJI_CARD_URLS,
  formatDateToMST,
  formatISODate,
  formatPhoneNumber,
  queryKeys,
  searchParamsToObject,
} from 'src/utils';

import { CardholderIsActiveForm } from './CardholderIsActiveForm';

import { useCardDetailsModal } from '../CardDetailsModal';

import type { CardCleaned, CardRaw } from 'src/types';

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;

const LABEL_WIDTH_PX = 110;

const tabsKey = 'cardholder';
const tabsQueryParamName = `${tabsKey}-tab`;

const PAGE_SIZE = 5;

export const CARDHOLDER_DETAILS_MODAL_ID = 'cardholder-details-modal';
export const useCardholderDetailsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: CARDHOLDER_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/cardholders', {
        queryParams: omit(currentSearchParams, [
          'selectedCardholderId',
          tabsQueryParamName,
        ]),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Cardholder Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      const isSamePage = pathname === getRoutePathName('/secure/cardholders');
      const selectedTab = currentSearchParams[tabsQueryParamName];
      navigate('/secure/cardholders', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          ...(!!selectedTab && { [tabsQueryParamName]: selectedTab }),
          selectedCardholderId: id,
        },
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const CardholderTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const selectedCardholderId = searchParams.get('selectedCardholderId');
  const { data: selectedCardholder } = useGetCardholder({
    id: selectedCardholderId ?? '',
  });
  return (
    <TrackingContextProvider
      trackingProps={{
        cardholderId: selectedCardholder?.id,
        cardholderStripeId: selectedCardholder?.stripeId,
        cardholderEmail: selectedCardholder?.email,
        cardholderFirstName: selectedCardholder?.name?.first,
        cardholderLastName: selectedCardholder?.name?.last,
        cardholderStatus: selectedCardholder?.status,
        cardholderCreatedAt: selectedCardholder?.createdAt
          ? new Date(selectedCardholder.createdAt).toISOString()
          : null,
        cardholderType: selectedCardholder?.cardholderType,
      }}
      contextName={TrackContext.cardholder}
      {...props}
    />
  );
};
const CardholderDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { navigate } = useAppRoutes();

  const { track, incrementMixpanelProfileData } = useTrack();
  const { privileges } = useGetUserPrivileges();
  const { features } = useGetFeatures();
  const { pathname, getRoutePathName, generatePath } = useAppRoutes();
  const { isOpen, openModal, closeModal } = useCardholderDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Cardholder Details',
        // ...(hasUnsavedChanges && {
        //   unsavedChanges: Object.entries(unsavedChanges)
        //     // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not using the key to filter
        //     .filter(([_key, { hasUnsavedChanges }]) => hasUnsavedChangepcards)
        //     .map(([label]) => label),
        // }),
      });
    },
  });
  const { openModal: openCardDetailsModal } = useCardDetailsModal();
  const [searchParams] = useSearchParams();
  const selectedCardholderId = searchParams.get('selectedCardholderId');
  const {
    data: {
      stripeId,
      billingAddress,
      createdAt,
      status,
      name,
      email,
      phoneNumber,
      dob,
      isActive,
      group,
      aggregateRemainingSpendLimitCents,
      aggregateSpentTotalCents,
    } = {},
    isLoading,
  } = useGetCardholder({
    id: selectedCardholderId ?? '',
  });

  const { data: { accountDetails } = {} } = useGetUserDetails();
  const orgCardTypes = accountDetails?.companyCardTypes;

  useEffect(() => {
    if (!isOpen && selectedCardholderId) {
      openModal(selectedCardholderId);
    }
  }, [isOpen, openModal, selectedCardholderId]);

  const isSamePage = pathname === getRoutePathName('/secure/cardholders');
  useEffect(() => {
    if (isOpen && !selectedCardholderId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, isSamePage, selectedCardholderId]);

  const handleCloseModal = () => {
    closeModal();
  };

  const createdAtObj = !!createdAt && new Date(createdAt);

  const isGroupInactive = !!group && group.status === GroupStatus.INACTIVE;
  const groupLink = generatePath('/secure/groups', undefined, {
    selectedGroupId: group?.id ?? '',
  });

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Cardholder Details
        </Typography>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
            justify-content: flex-end;
          `}
        >
          {!isLoading && (
            <CardholderIsActiveForm
              cardholderId={stripeId ?? ''}
              disabled={!stripeId || isLoading || !privileges.card_details}
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <ModalTabs
              tabsKey={tabsKey}
              tabs={[
                {
                  label: 'Info',
                  content: (
                    <Box>
                      {isGroupInactive && (
                        <Box
                          css={css`
                            padding: 8px 18px 0;
                          `}
                        >
                          <Alert
                            severity="warning"
                            css={css`
                              flex: 1 1 100%;
                            `}
                          >
                            <Typography>
                              Group is not active. Cardholder will not be able
                              to be used while group is inactive.{' '}
                              <Typography
                                component="a"
                                href={groupLink}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                View Group
                              </Typography>
                            </Typography>
                          </Alert>
                        </Box>
                      )}
                      <Box
                        css={css`
                          padding: 10px 8px;
                        `}
                      >
                        <DataRow
                          label="Name"
                          value={name?.full}
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        <DataRow
                          label="Created On"
                          value={
                            !!createdAtObj && formatDateToMST(createdAtObj)
                          }
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        <DataRow
                          label="Status"
                          value={
                            <StatusChip
                              status={status ?? ''}
                              type="cardholder"
                            />
                          }
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        <DataRow
                          label="Email"
                          value={email}
                          isPrivate
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        <DataRow
                          label="Phone"
                          value={formatPhoneNumber(phoneNumber ?? '')}
                          isPrivate
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        <DataRow
                          label="DOB"
                          value={formatISODate(dob ?? '')}
                          isPrivate
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        <DataRow
                          label="Billing Address"
                          value={`${billingAddress?.line1}${
                            billingAddress?.line2
                              ? `${' '}${billingAddress?.line2}`
                              : ''
                          } ${billingAddress?.city}, ${billingAddress?.state} ${billingAddress?.postal_code}`}
                          isPrivate
                          labelWidth={LABEL_WIDTH_PX}
                        />
                        {features?.groups && (
                          <DataRow
                            label="Group"
                            value={
                              <Typography
                                component="a"
                                href={groupLink}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                {group?.name}
                              </Typography>
                            }
                            labelWidth={LABEL_WIDTH_PX}
                          />
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  label: 'Spend Overview',
                  content: (
                    <Box>
                      <Box
                        css={css`
                          padding: 10px 8px;
                        `}
                      >
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            gap: 16px 8px;
                            flex: 1 1 100%;
                            padding: 16px 24px;
                          `}
                        >
                          <Box
                            css={css`
                              display: flex;
                              flex: 1 1 100px;
                              justify-content: center;
                              background-color: ${COLOR_PALETTE.lightBackground};
                              padding: 10px 10px 14px 10px;
                              border-radius: 8px;
                            `}
                          >
                            <DataItemDisplay
                              css={css`
                                flex: 0 0 auto;
                                gap: 10px;
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-value {
                                  text-align: center;
                                }
                              `}
                              label={
                                <>
                                  Unspent Limits
                                  <InfoPopover
                                    contents="Total remaing spend limit of all active cards with a spend limit set."
                                    name="Unspent Limits"
                                    css={css`
                                      margin-left: 2px;
                                    `}
                                  />
                                </>
                              }
                              value={
                                <Typography
                                  css={css`
                                    font-size: 20px;
                                    font-weight: 700;
                                  `}
                                >
                                  <FancyCurrencyDisplay
                                    amountCents={
                                      aggregateRemainingSpendLimitCents ?? 0
                                    }
                                    isPlain
                                  />
                                </Typography>
                              }
                            />
                          </Box>
                          <Box
                            css={css`
                              display: flex;
                              flex: 1 1 100px;
                              justify-content: center;
                              background-color: ${COLOR_PALETTE.lightBackground};
                              padding: 10px 10px 14px 10px;
                              border-radius: 8px;
                            `}
                          >
                            <DataItemDisplay
                              css={css`
                                flex: 0 0 auto;
                                gap: 10px;
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-value {
                                  text-align: center;
                                }
                              `}
                              label="Total Spent"
                              value={
                                <Typography
                                  css={css`
                                    font-size: 20px;
                                    font-weight: 700;
                                  `}
                                >
                                  <FancyCurrencyDisplay
                                    amountCents={aggregateSpentTotalCents}
                                    isPlain
                                  />
                                </Typography>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ),
                },

                ...(privileges?.cards
                  ? [
                      {
                        label: 'Cards',
                        tabOverflow: 'hidden' as const,
                        content: (
                          <Box
                            css={css`
                              height: 100%;
                              display: flex;
                              flex-direction: column;
                              padding: 10px 0;
                              gap: 4px 16px;
                              flex: 1 1 auto;
                              position: relative;
                            `}
                          >
                            <InlineCreateCardForm
                              cardholderId={selectedCardholderId ?? ''}
                              onSuccess={(data) => {
                                track({
                                  label: 'Created card',
                                  type: TrackType.effect,
                                  isSuccessful: true,
                                  isFromExistingCardholder: true,
                                  cardId: data.id,
                                  cardExpMonth: data.exp_month,
                                  cardExpYear: data.exp_year,
                                  cardSpendControlAmountCents: null,
                                  cardStatus: data.status,
                                  cardholderId: stripeId,
                                  cardCreatedAt: new Date(
                                    data.created,
                                  ).toISOString(),
                                });
                                incrementMixpanelProfileData('createCardCount');
                              }}
                              onError={() => {
                                track({
                                  label: 'Created card',
                                  type: TrackType.effect,
                                  isSuccessful: false,
                                  isFromExistingCardholder: true,
                                  cardholderId: stripeId,
                                });
                              }}
                            >
                              {({
                                disabled: isCreateCardButtonDisabled,
                                onClick: createCardOnClick,
                                button: CreateCardButton,
                              }) => (
                                <>
                                  <PaginatedTableWrapper<CardRaw, CardCleaned>
                                    endpointUrl={
                                      SERVICE_BENJI_CARD_URLS.CARDS_GET
                                    }
                                    filters={{
                                      cardholderId: selectedCardholderId ?? '',
                                    }}
                                    queryKeyBase={queryKeys.cards._baseKey}
                                    dataFormatter={cleanCards}
                                    requiredPrivileges={[Privilege.cards]}
                                    shouldUseQueryParamsSearchState={false}
                                    pageSize={PAGE_SIZE}
                                  >
                                    {({
                                      queryResult: { isLoading, isError },
                                      pagination,
                                      paginationData,
                                      resultCount,
                                      pageCount,
                                    }) => (
                                      <>
                                        <Box
                                          css={css`
                                            display: flex;
                                            gap: 8px 16px;
                                            padding-bottom: 12px;
                                            margin: 0 14px;
                                            position: relative;
                                            border-bottom: 1px solid
                                              ${COLOR_PALETTE.borderDark};
                                            min-height: 53px;
                                          `}
                                        >
                                          {privileges.issue_card && (
                                            <>
                                              {isActive ? (
                                                <CreateCardButton
                                                  onClick={() => {
                                                    track({
                                                      label:
                                                        'Clicked create card from cardholder button',
                                                      type: TrackType.action,
                                                      actionType: 'click',
                                                    });
                                                    createCardOnClick();
                                                  }}
                                                  disabled={
                                                    isCreateCardButtonDisabled
                                                  }
                                                />
                                              ) : (
                                                <Alert
                                                  severity="warning"
                                                  css={css`
                                                    width: 100%;
                                                  `}
                                                >
                                                  Cardholder must be active to
                                                  create new cards.
                                                </Alert>
                                              )}
                                            </>
                                          )}
                                          {!!resultCount && (
                                            <Link
                                              onClick={() => {
                                                navigate('/secure/cards', {
                                                  queryParams: {
                                                    filters: JSON.stringify({
                                                      cardholderId:
                                                        selectedCardholderId,
                                                    }),
                                                  },
                                                });
                                              }}
                                              css={css`
                                                margin-left: auto;
                                                font-weight: 500;
                                                line-height: normal;
                                                align-self: center;
                                                padding: 0 2px;
                                              `}
                                            >
                                              View All
                                            </Link>
                                          )}
                                        </Box>

                                        {!!paginationData.nodes.length && (
                                          <Box
                                            css={css`
                                              display: flex;
                                              flex-direction: column;
                                              flex: 1 1 auto;
                                              gap: 8px;
                                              padding-bottom: 12px;
                                              overflow-y: auto;
                                            `}
                                          >
                                            {paginationData.nodes.map(
                                              (card, index) => (
                                                <Box
                                                  key={card.id}
                                                  {...(privileges?.cards && {
                                                    onClick: () =>
                                                      openCardDetailsModal(
                                                        card.stripeId,
                                                      ),
                                                  })}
                                                  css={css`
                                                    display: flex;
                                                    gap: 8px 16px;
                                                    padding: 14px;
                                                    border-bottom: 1px solid
                                                      ${index + 1 === PAGE_SIZE
                                                        ? 'transparent'
                                                        : COLOR_PALETTE.border};

                                                    &:hover {
                                                      background-color: ${COLOR_PALETTE.tableRowHover};
                                                      cursor: pointer;
                                                    }
                                                  `}
                                                >
                                                  <Box>
                                                    <Typography
                                                      css={css`
                                                        font-size: 15px;
                                                        font-weight: 600;
                                                      `}
                                                    >
                                                      {index +
                                                        1 +
                                                        ((pagination.state
                                                          .page ?? 1) -
                                                          1) *
                                                          PAGE_SIZE}
                                                      .
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    css={css`
                                                      display: flex;
                                                      gap: 12px 16px;
                                                      flex-wrap: wrap;
                                                    `}
                                                  >
                                                    <Typography
                                                      css={css`
                                                        flex: 1 1 100%;
                                                        font-weight: 600;
                                                      `}
                                                    >
                                                      {formatDateToMST(
                                                        new Date(
                                                          card.createdAt,
                                                        ),
                                                      )}
                                                    </Typography>
                                                    <Box
                                                      css={css`
                                                        width: 100%;
                                                        display: flex;
                                                        gap: 12px 18px;
                                                      `}
                                                    >
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 208px;
                                                        `}
                                                        size="small"
                                                        label="Nickname"
                                                        value={
                                                          <span className="highlight-block">
                                                            {card.nickname}
                                                          </span>
                                                        }
                                                      />
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 92px;
                                                        `}
                                                        size="small"
                                                        label="Card No."
                                                        value={
                                                          <span className="highlight-block">
                                                            **** ****{' '}
                                                            {card.lastFour}
                                                          </span>
                                                        }
                                                      />
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 90px;
                                                        `}
                                                        size="small"
                                                        label="Status"
                                                        value={
                                                          <Box
                                                            css={css`
                                                              display: flex;
                                                            `}
                                                          >
                                                            <StatusChip
                                                              status={
                                                                card.status
                                                              }
                                                              type="card"
                                                            />
                                                          </Box>
                                                        }
                                                      />
                                                    </Box>

                                                    <Box
                                                      css={css`
                                                        width: 100%;
                                                        display: flex;
                                                        gap: 12px 16px;
                                                      `}
                                                    >
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 96px;
                                                        `}
                                                        size="small"
                                                        label="Spent"
                                                        value={
                                                          <FancyCurrencyDisplay
                                                            amountCents={
                                                              card.spentTotalCents
                                                                ? card.spentTotalCents *
                                                                  -1
                                                                : 0
                                                            }
                                                            isPlain
                                                          />
                                                        }
                                                      />
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 96px;
                                                        `}
                                                        size="small"
                                                        label="Remaining Limit"
                                                        value={
                                                          card.isActive &&
                                                          card.spendLimitCents ? (
                                                            <FancyCurrencyDisplay
                                                              amountCents={
                                                                card.spendLimitRemainingCents ??
                                                                0
                                                              }
                                                              isPlain
                                                            />
                                                          ) : null
                                                        }
                                                      />
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 96px;
                                                        `}
                                                        size="small"
                                                        label="Spend Limit"
                                                        value={
                                                          card.isActive &&
                                                          card.spendLimitCents ? (
                                                            <FancyCurrencyDisplay
                                                              amountCents={
                                                                card.spendLimitCents
                                                              }
                                                              isPlain
                                                            />
                                                          ) : null
                                                        }
                                                      />
                                                      {(orgCardTypes?.length ??
                                                        0) > 1 && (
                                                        <DataItemDisplay
                                                          css={css`
                                                            flex: 0 0 100px;
                                                          `}
                                                          size="small"
                                                          label="Type"
                                                          value={
                                                            CardTypeLabel[
                                                              card.type
                                                            ] ?? card.type
                                                          }
                                                        />
                                                      )}
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              ),
                                            )}
                                          </Box>
                                        )}
                                        <Box
                                          css={css`
                                            padding: 0 14px;
                                          `}
                                        >
                                          <TableLoader isLoading={isLoading} />
                                          <TableError
                                            isLoading={isLoading}
                                            isError={isError}
                                          />
                                          <TableNoResults
                                            isLoading={isLoading}
                                            resultCount={resultCount}
                                            noResultMessage="No cards"
                                            css={css`
                                              height: initial;
                                              flex-grow: 1;
                                            `}
                                          />
                                          {(pageCount ?? 0) > 1 && (
                                            <TablePagination
                                              resultCount={resultCount}
                                              pageCount={pageCount}
                                              pagination={pagination}
                                              noSidePadding
                                            />
                                          )}
                                        </Box>
                                      </>
                                    )}
                                  </PaginatedTableWrapper>
                                </>
                              )}
                            </InlineCreateCardForm>
                          </Box>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </>
        )}
      </DialogContent>
    </Popover>
  );
};

export const CardholderDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <CardholderTrackingContextProvider>
      <CardholderDetailsModalBase anchorEl={anchorEl} />
    </CardholderTrackingContextProvider>
  );
};
