import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS } from 'src/utils';

import type { CardType } from 'src/types/cards';

interface NoCard {
  card: false;
  message: string;
}
interface CardDetailsRaw {
  card: {
    id: string;
    card_id: string;
    spend_amount_cents: number;
    member_first_name: string;
    member_last_name: string;
    agree_to_terms_required: boolean;
    provider_name: string;
    service_date: string;
    member_birth_date: string | null;
    payer_company_name: string;
    cardholderName: string;
    cardholderBillingAddress: {
      city: string;
      country: string;
      line1: string;
      line2: string | null;
      postal_code: string;
      state: string;
    };
    type: CardType;
    spend_limit_remaining_cents: number;
  };
}
interface CardDetails {
  hasCard: boolean;
  details?: {
    id: CardDetailsRaw['card']['id'];
    cardId: CardDetailsRaw['card']['card_id'];
    type: CardDetailsRaw['card']['type'];
    remainingSpendLimitCents: CardDetailsRaw['card']['spend_limit_remaining_cents'];
    spendAmountCents: CardDetailsRaw['card']['spend_amount_cents'];
    memberName: {
      first: CardDetailsRaw['card']['member_first_name'];
      last: CardDetailsRaw['card']['member_last_name'];
      full: string;
    };
    providerName: CardDetailsRaw['card']['provider_name'];
    serviceDate: CardDetailsRaw['card']['service_date'];
    memberBirthDate: CardDetailsRaw['card']['member_birth_date'];
    payerCompanyName: CardDetailsRaw['card']['payer_company_name'];
    cardholderName: CardDetailsRaw['card']['cardholderName'];
    cardholderBillingAddress: CardDetailsRaw['card']['cardholderBillingAddress'];
  };
}

const requestCardDetails = async ({
  token,
  memberBirthDate,
}: {
  token: string;
  memberBirthDate: string;
}) => {
  const { data } = await axios.post<CardDetailsRaw | NoCard>(
    generatePath(SERVICE_BENJI_CARD_URLS.CARD_SEND_DETAILS, {
      token,
    }),
    { dob: memberBirthDate },
  );

  const cleanedData: CardDetails = {
    hasCard: data.card ? true : false,
    details: data.card
      ? {
          id: data.card.id,
          cardId: data.card.card_id,
          type: data.card.type,
          remainingSpendLimitCents: data.card.spend_limit_remaining_cents,
          spendAmountCents: data.card.spend_amount_cents,
          memberName: {
            first: data.card.member_first_name,
            last: data.card.member_last_name,
            full: `${data.card.member_first_name} ${data.card.member_last_name}`,
          },
          providerName: data.card.provider_name,
          serviceDate: data.card.service_date,
          memberBirthDate: data.card.member_birth_date,
          payerCompanyName: data.card.payer_company_name,
          cardholderName: data.card.cardholderName,
          cardholderBillingAddress: data.card.cardholderBillingAddress,
        }
      : undefined,
  };

  return cleanedData;
};

export const useRequestCardDetails = () => {
  return useMutation({
    mutationFn: ({
      token,
      memberBirthDate,
    }: {
      token: string;
      memberBirthDate: string;
    }) =>
      requestCardDetails({
        token: token ?? '',
        memberBirthDate,
      }),
  });
};
