import { css } from '@emotion/react';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Link,
  CircularProgress,
  IconButton,
  Popover,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DataRow,
  FancyCurrencyDisplay,
  SlideLeftTransition,
} from 'src/components';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import { useAppRoutes } from 'src/hooks';
import { useGetFundsAssignment } from 'src/hooks/useGetFundsAssignment';
import { COLOR_PALETTE } from 'src/theme';
import { searchParamsToObject } from 'src/utils';

const FUNDS_ASSIGNMENT_DETAILS_MODAL_ID = 'funds-assignment-details-modal';

export const useFundsAssignmentDetailsModal = (
  props?: Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: FUNDS_ASSIGNMENT_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/funds-management', {
        queryParams: omit(currentSearchParams, 'selectedFundsAssignmentId'),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Fund Assignment Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      const isSamePage =
        pathname === getRoutePathName('/secure/funds-management');
      navigate('/secure/funds-management', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          selectedFundsAssignmentId: id,
          'funds-management-tab': '1',
        },
        replace: isSamePage,
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const FundsAssignmentTrackingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const selectedFundsAssignmentId = searchParams.get(
    'selectedFundsAssignmentId',
  );
  const { data: selectedFundsAssignment } = useGetFundsAssignment({
    id: selectedFundsAssignmentId ?? '',
  });

  return (
    <TrackingContextProvider
      trackingProps={{
        fundsAssignmentId: selectedFundsAssignment?.id,
        fundsAssignmentAmountCents: selectedFundsAssignment?.amountCents,
      }}
      contextName={TrackContext['funds-assignment']}
      {...props}
    />
  );
};
const FundsAssignmentDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { track } = useTrack();
  const { isOpen, openModal, closeModal } = useFundsAssignmentDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Fund Assignment Details',
      });
    },
  });
  const { pathname, getRoutePathName, generatePath } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const selectedFundsAssignmentId = searchParams.get(
    'selectedFundsAssignmentId',
  );
  const { data: selectedFundsAssignment, isLoading } = useGetFundsAssignment({
    id: selectedFundsAssignmentId ?? '',
  });
  useEffect(() => {
    if (!isOpen && selectedFundsAssignmentId) {
      openModal(selectedFundsAssignmentId);
    }
  }, [isOpen, openModal, selectedFundsAssignmentId]);

  const isSamePage = pathname === getRoutePathName('/secure/funds-management');
  useEffect(() => {
    if (isOpen && !selectedFundsAssignmentId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedFundsAssignmentId, isSamePage]);

  const handleCloseModal = () => {
    closeModal();
  };

  const {
    id,
    prettyCreatedAtDate,
    createdByInternalUserId,
    createdByInternalUserName,
    createdByExternalId,
    amountCents,
    group,
  } = selectedFundsAssignment ?? {};

  const groupLink = generatePath('/secure/groups', undefined, {
    selectedGroupId: group?.id ?? '',
  });

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Fund Assignment Details
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            css={css`
              padding: 10px 8px;
            `}
          >
            <DataRow label="Date" value={prettyCreatedAtDate} />
            <DataRow
              label="Amount"
              value={<FancyCurrencyDisplay amountCents={amountCents} />}
            />
            <DataRow
              label="Group"
              value={
                <Typography
                  component="a"
                  href={groupLink}
                  css={css`
                    color: inherit;
                  `}
                >
                  {group?.name}
                </Typography>
              }
            />
            <DataRow
              label="Assigned By"
              value={`${
                createdByInternalUserId
                  ? createdByInternalUserName
                  : `${createdByExternalId} (via API)`
              }`}
              isPrivate
            />
          </Box>
        )}
        <Typography
          variant="body1"
          css={css`
            margin-top: auto;
            width: 100%;
            padding: 16px;
            text-align: center;
            font-size: 12px;
            font-weight: 300;
            line-height: 166.66%;
            color: ${COLOR_PALETTE.lightTextOnLight};
          `}
        >
          If you have any issues with this fund assignment, please contact us at{' '}
          <Link
            href={`mailto:support@benjicard.com?subject=Issue with fund assignment (${id})`}
            css={css`
              color: ${COLOR_PALETTE.blueLink};
            `}
          >
            support@benjicard.com
          </Link>
          .
        </Typography>
      </DialogContent>
    </Popover>
  );
};

export const FundsAssignmentDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <FundsAssignmentTrackingContextProvider>
      <FundsAssignmentDetailsModalBase anchorEl={anchorEl} />
    </FundsAssignmentTrackingContextProvider>
  );
};
