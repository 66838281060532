import { css } from '@emotion/react';
import { AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Card, Typography } from '@mui/material';
import { get } from 'lodash';
import { useRef, useState } from 'react';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  InfoPopover,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableMenu,
  TableNoResults,
  TablePagination,
} from 'src/components';
import {
  GroupDetailsModal,
  useGroupDetailsModal,
} from 'src/components/modals/GroupDetailsModal';
import { SearchBar } from 'src/components/PaginatedTableWrapper/SearchBar';
import { FilterType, TableFilters } from 'src/components/TableFilters';
import { TrackType, useToast, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import {
  DownloadEntity,
  useDownloadTableCsv,
} from 'src/hooks/useDownloadTableCsv';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { AccountStatus } from 'src/types/account';
import { GroupStatus } from 'src/types/groups';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { cleanGroups } from './utils';

import type { PaginatedTableFilter } from 'src/components/PaginatedTableWrapper';
import type { TableColumn } from 'src/components/Table';
import type { TableFilter } from 'src/components/TableFilters';
import type { GroupCleaned, GroupRaw } from 'src/types/groups';

export const GroupsPage = () => {
  const { navigate } = useAppRoutes();
  const { track } = useTrack();
  const { setToast } = useToast();

  const cardRef = useRef<HTMLDivElement>(null);
  const endpointUrl = SERVICE_BENJI_CARD_URLS.GROUPS_GET;
  const { privileges, isLoading: areUserPrivilegesLoading } =
    useGetUserPrivileges();
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;

  const isPageLoading = areUserPrivilegesLoading || isUserLoading;

  const columns: ({ width: string } & TableColumn<GroupCleaned>)[] = [
    {
      label: 'Name',
      renderCell: ({ name }) => name,
      width: 'minmax(150px, 300px)',
    },
    ...(hasGroupsLedgerEnabled
      ? [
          ...(privileges.balance
            ? [
                {
                  label: 'Available',
                  renderCell: ({ balanceAvailableCents }: GroupCleaned) => {
                    return (
                      <FancyCurrencyDisplay
                        amountCents={balanceAvailableCents ?? 0}
                        isPlain
                      />
                    );
                  },
                  width: '140px',
                },
              ]
            : []),
          {
            label: 'Allocated',
            renderLabel: () => {
              return (
                <>
                  Allocated
                  <InfoPopover
                    contents="This is the total of every active card's spend limit minus the amount already spent on the card. In other words, this is the amount remaining for every card to hit its spend limit max."
                    name="Allocated Funds"
                    css={css`
                      margin-left: 2px;
                    `}
                  />
                </>
              );
            },
            renderCell: ({
              balanceAvailableCents,
              balanceAllocatedCents,
              balancePercentAllocated,
            }: GroupCleaned) => {
              const percent = Number(
                (balancePercentAllocated * 100).toFixed(2),
              );
              const isOver100 = percent > 100;
              return (
                <Box
                  css={css`
                    display: flex;
                    gap: 8px;
                    align-items: baseline;
                  `}
                >
                  <FancyCurrencyDisplay
                    amountCents={balanceAllocatedCents ?? 0}
                    isPlain
                  />
                  {privileges.balance && (
                    <Typography
                      css={css`
                        color: ${isOver100 ? COLOR_PALETTE.error : 'inherit'};
                        font-size: 15px;
                        font-weight: 300;
                      `}
                    >
                      {balanceAvailableCents === 0 &&
                      balanceAllocatedCents === 0 ? null : (
                        <>({isOver100 ? '>100' : percent}%)</>
                      )}
                    </Typography>
                  )}
                </Box>
              );
            },
            width: '200px',
          },
        ]
      : [
          {
            label: 'Allocated',
            renderLabel: () => {
              return (
                <>
                  Allocated
                  <InfoPopover
                    contents="This is the total of every active card's spend limit minus the amount already spent on the card. In other words, this is the amount remaining for every card to hit its spend limit max."
                    name="Allocated Funds"
                    css={css`
                      margin-left: 2px;
                    `}
                  />
                </>
              );
            },
            renderCell: ({ balanceAllocatedCents, status }: GroupCleaned) => {
              return (
                <Box
                  css={css`
                    display: flex;
                    gap: 8px;
                    align-items: baseline;
                  `}
                >
                  {status === GroupStatus.ACTIVE && (
                    <FancyCurrencyDisplay
                      amountCents={balanceAllocatedCents ?? 0}
                      isPlain
                    />
                  )}
                </Box>
              );
            },
            width: '200px',
          },
        ]),
    {
      label: 'Status',
      width: '120px',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="group" />
          </Box>
        );
      },
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const { openModal: openGroupDetailsModal } = useGroupDetailsModal();

  const [filters, setFilters] = useState<PaginatedTableFilter>({});

  const { mutate: download, isLoading: isDownloading } = useDownloadTableCsv();

  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 96px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <PaginatedTableWrapper<GroupRaw, GroupCleaned>
            endpointUrl={endpointUrl}
            queryKeyBase={queryKeys.groups._baseKey}
            dataFormatter={cleanGroups}
            requiredPrivileges={[Privilege.cards]}
            filters={filters}
          >
            {({
              queryResult: {
                isLoading,
                isError,
                data: { invalidFiltersWarningMessages } = {},
              },
              pagination,
              paginationData,
              resultCount,
              pageCount,
              searchProps,
            }) => {
              return (
                <>
                  <Box
                    css={css`
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      gap: 8px 16px;
                      padding: 16px 16px 0;
                    `}
                  >
                    <Typography
                      variant="h2"
                      css={css`
                        flex-grow: 999;
                      `}
                    >
                      Groups
                    </Typography>

                    <Box
                      css={css`
                        display: flex;
                        flex-wrap: wrap-reverse;
                        gap: 16px;
                        flex-grow: 1;
                        justify-content: flex-end;
                      `}
                    >
                      <SearchBar {...searchProps} isLoading={!!isLoading} />
                      {privileges?.create_edit_groups && (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => navigate('/secure/groups/new-group')}
                          disabled={
                            isPageLoading ||
                            userData?.accountDetails.status !==
                              AccountStatus.ACTIVE
                          }
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                        >
                          Create Group
                        </Button>
                      )}
                      <TableMenu
                        disabled={isPageLoading}
                        menuItems={[
                          {
                            label: `Download CSV`,
                            onClick: () => {
                              track({
                                label: 'Clicked Download Group Table',
                                type: TrackType.action,
                                actionType: 'click',
                              });

                              download(
                                {
                                  downloadEntity: DownloadEntity.GROUPS,
                                  filters: {
                                    ...filters,
                                    search: searchProps.searchValue,
                                  },
                                },
                                {
                                  onError: (error) => {
                                    const message = get(
                                      error,
                                      'message',
                                      'An error occurred while downloading the CSV. Try different filters or try again later.',
                                    );
                                    setToast({
                                      message,
                                      severity: 'error',
                                    });
                                  },
                                },
                              );
                            },
                            disabled: isDownloading,
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <AsynchronouslyLoadedSection isLoading={isPageLoading}>
                    <Box
                      css={css`
                        width: 100%;
                        display: flex;
                        gap: 8px 16px;
                        flex-wrap: nowrap;
                        padding: 0 16px;
                      `}
                    >
                      <TableFilters
                        setFilters={setFilters}
                        priorityFilterCount={1}
                        filters={[
                          {
                            name: 'status',
                            label: 'Status',
                            type: FilterType.SELECT,
                            defaultValue: GroupStatus.ACTIVE,
                            widthPx: 110,
                            options: [
                              { value: '', label: 'All' },
                              {
                                value: GroupStatus.ACTIVE,
                                label: 'Active',
                              },
                              {
                                value: GroupStatus.INACTIVE,
                                label: 'Inactive',
                              },
                            ],
                          },
                          ...(hasGroupsLedgerEnabled && privileges.balance
                            ? [
                                {
                                  name: 'isOverAllocated',
                                  label: 'Over Allocated',
                                  type: FilterType.SWITCH,
                                  defaultValue: false,
                                } as TableFilter,
                              ]
                            : []),
                        ]}
                      />
                    </Box>
                    <Table<GroupCleaned>
                      data={paginationData}
                      pagination={pagination}
                      columns={columns}
                      isLoading={isLoading || isPageLoading}
                      onRowClick={({ id }) => {
                        openGroupDetailsModal(id);
                      }}
                      columnWidths={columns.map(({ width }) => width).join(' ')}
                      css={css`
                        margin-top: 10px;
                        width: 100%;
                      `}
                    />
                    <TableLoader isLoading={isLoading} />
                    <TableError isLoading={isLoading} isError={isError} />
                    <TableNoResults
                      isLoading={isLoading}
                      resultCount={resultCount}
                      searchTerms={searchProps.searchValue}
                      noResultMessage={
                        invalidFiltersWarningMessages?.length
                          ? `${invalidFiltersWarningMessages
                              .map(({ message }) => message)
                              .join(', ')}.`
                          : `Please try a different search term. You can search by the group${`'`}s name.`
                      }
                    />
                    <TablePagination
                      resultCount={resultCount}
                      pageCount={pageCount}
                      pagination={pagination}
                    />
                  </AsynchronouslyLoadedSection>
                </>
              );
            }}
          </PaginatedTableWrapper>
        </Card>
      </Box>
      <GroupDetailsModal anchorEl={cardRef.current} />
    </>
  );
};
