import { css } from '@emotion/react';
import { MoveDown as MoveDownIcon } from '@mui/icons-material';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Link,
  Button,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DataRow,
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  SlideLeftTransition,
  StatusChip,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { CircleChart } from 'src/components/CircleChart';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { ModalTabs } from 'src/components/ModalTabs';
import {
  TrackContext,
  TrackType,
  TrackingContextProvider,
  useModal,
  useTrack,
} from 'src/context';
import {
  useAppRoutes,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { cleanFundAssignments } from 'src/pages/FundsTransfers/utils';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { GroupStatus } from 'src/types/groups';
import {
  queryKeys,
  searchParamsToObject,
  SERVICE_BENJI_CARD_URLS,
} from 'src/utils';

import { GroupIsActiveForm } from './GroupIsActiveForm';
import { GroupNameForm } from './GroupNameForm';
import { useGetGroupDetails } from './useGetGroupDetails';

import { useFundsAssignmentDetailsModal } from '../FundsAssignmentDetailsModal';

import type {
  FundsAssignmentCleaned,
  FundsAssignmentRaw,
} from 'src/types/funds-assignments';

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;

const tabsKey = 'group';
const tabsQueryParamName = `${tabsKey}-tab`;

const PAGE_SIZE = 5;

const GROUP_DETAILS_MODAL_ID = 'group-details-modal';

export const useGroupDetailsModal = (
  props?: Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: GROUP_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/groups', {
        queryParams: omit(currentSearchParams, [
          'selectedGroupId',
          tabsQueryParamName,
        ]),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Group Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      const isSamePage = pathname === getRoutePathName('/secure/groups');
      const selectedTab = currentSearchParams[tabsQueryParamName];
      navigate('/secure/groups', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          ...(!!selectedTab && { [tabsQueryParamName]: selectedTab }),
          selectedGroupId: id,
        },
        replace: isSamePage,
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const GroupTrackingContextProvider = (props: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const selectedGroupId = searchParams.get('selectedGroupId');
  const { data: selectedGroup } = useGetGroupDetails({
    id: selectedGroupId ?? '',
  });

  return (
    <TrackingContextProvider
      trackingProps={{
        groupId: selectedGroup?.id,
        groupName: selectedGroup?.name,
      }}
      contextName={TrackContext['funds-transfer']}
      {...props}
    />
  );
};
const GroupDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { track } = useTrack();
  const { isOpen, openModal, closeModal } = useGroupDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Funds Transfer Details',
      });
    },
  });
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const { privileges } = useGetUserPrivileges();
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const { openModal: openFundsAssignmentModal } =
    useFundsAssignmentDetailsModal();
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;
  const [searchParams] = useSearchParams();
  const selectedGroupId = searchParams.get('selectedGroupId');
  const { data: selectedGroup, isLoading: isGroupDetailsLoaading } =
    useGetGroupDetails({
      id: selectedGroupId ?? '',
    });
  useEffect(() => {
    if (!isOpen && selectedGroupId) {
      openModal(selectedGroupId);
    }
  }, [isOpen, openModal, selectedGroupId]);

  const isSamePage = pathname === getRoutePathName('/secure/groups');
  useEffect(() => {
    if (isOpen && !selectedGroupId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedGroupId, isSamePage]);

  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({});
  const hasUnsavedChanges = Object.values(unsavedChanges).some(
    ({ hasUnsavedChanges }) => hasUnsavedChanges,
  );
  const handleSetUnsavedChanges = useMemo(
    () => (newUnsavedChanges: UnsavedChanges) => {
      setUnsavedChanges((unsavedChanges) => ({
        ...unsavedChanges,
        ...newUnsavedChanges,
      }));
    },
    [],
  );
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState(false);

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      if (showUnsavedChangesWarning) {
        setUnsavedChanges({});
        setShowUnsavedChangesWarning(false);
        closeModal();
      } else {
        setShowUnsavedChangesWarning(true);
      }
    } else {
      setUnsavedChanges({});
      setShowUnsavedChangesWarning(false);
      closeModal();
    }
  };

  const isLoading = isUserLoading || isGroupDetailsLoaading;

  const { name, status, id } = selectedGroup ?? {};

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <GridCloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Group Details
        </Typography>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
            justify-content: flex-end;
          `}
        >
          {!isLoading && (
            <GroupIsActiveForm
              isGroupActive={status === GroupStatus.ACTIVE}
              groupId={selectedGroupId ?? ''}
              disabled={
                !selectedGroupId || isLoading || !privileges.create_edit_groups
              }
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ModalTabs
            tabsKey={tabsKey}
            tabs={[
              {
                label: 'Info',
                content: (
                  <>
                    <Box
                      css={css`
                        padding: 10px 8px;
                      `}
                    >
                      <DataRow
                        label="Name"
                        value={
                          privileges.create_edit_groups ? (
                            <GroupNameForm
                              name={name ?? ''}
                              groupId={id ?? ''}
                              setUnsavedChanges={handleSetUnsavedChanges}
                              disabled={isLoading}
                            />
                          ) : (
                            name
                          )
                        }
                      />
                      <DataRow
                        label="Status"
                        value={
                          <StatusChip status={status ?? ''} type="group" />
                        }
                      />
                    </Box>
                    <Box
                      css={css`
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      {privileges.cards && (
                        <Link
                          onClick={() => {
                            navigate('/secure/cardholders', {
                              queryParams: {
                                filters: JSON.stringify({
                                  groupId: selectedGroupId,
                                  ...(status === GroupStatus.INACTIVE && {
                                    status: '',
                                  }),
                                }),
                              },
                            });
                          }}
                          css={css`
                            font-weight: 500;
                            line-height: normal;
                            padding: 4px 8px;
                          `}
                        >
                          View All Cardholders
                        </Link>
                      )}
                      {privileges.cards && (
                        <Link
                          onClick={() => {
                            navigate('/secure/cards', {
                              queryParams: {
                                filters: JSON.stringify({
                                  groupId: selectedGroupId,
                                  ...(status === GroupStatus.INACTIVE && {
                                    status: '',
                                  }),
                                }),
                              },
                            });
                          }}
                          css={css`
                            font-weight: 500;
                            line-height: normal;
                            padding: 4px 8px;
                          `}
                        >
                          View All Cards
                        </Link>
                      )}
                      {privileges.transactions && (
                        <Link
                          onClick={() => {
                            navigate('/secure/transactions', {
                              queryParams: {
                                filters: JSON.stringify({
                                  groupId: selectedGroupId,
                                }),
                              },
                            });
                          }}
                          css={css`
                            font-weight: 500;
                            line-height: normal;
                            padding: 4px 8px;
                          `}
                        >
                          View All Transactions
                        </Link>
                      )}
                      {privileges.dispute && (
                        <Link
                          onClick={() => {
                            navigate('/secure/disputes', {
                              queryParams: {
                                filters: JSON.stringify({
                                  groupId: selectedGroupId,
                                }),
                              },
                            });
                          }}
                          css={css`
                            font-weight: 500;
                            line-height: normal;
                            padding: 4px 8px;
                          `}
                        >
                          View All Disputes
                        </Link>
                      )}
                    </Box>
                  </>
                ),
              },
              ...(hasGroupsLedgerEnabled && privileges.balance
                ? [
                    {
                      label: 'Spend Overview',
                      content: (
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            gap: 16px 8px;
                            flex: 1 1 100%;
                            padding: 16px 24px;
                          `}
                        >
                          <Box
                            css={css`
                              flex: 1 1 100%;
                              display: flex;
                              justify-content: center;
                            `}
                          >
                            <CircleChart
                              size={224}
                              strokeWidth={30}
                              value={selectedGroup?.balanceAllocatedCents ?? 0}
                              max={selectedGroup?.balanceAvailableCents || 1}
                              label={
                                <DataItemDisplay
                                  css={css`
                                    gap: 0;
                                    .data-item-display-label {
                                      justify-content: center;
                                    }
                                  `}
                                  label="Available Balance"
                                  value={
                                    <FancyCurrencyDisplay
                                      size="lg"
                                      amountCents={
                                        selectedGroup?.balanceAvailableCents ??
                                        0
                                      }
                                      css={css`
                                        font-size: 20px;
                                        font-weight: 700;
                                        text-align: center;
                                        width: 100%;
                                        display: block;
                                      `}
                                    />
                                  }
                                />
                              }
                            />
                          </Box>
                          <Box
                            css={css`
                              display: flex;
                              flex: 1 1 100px;
                              justify-content: center;
                            `}
                          >
                            <DataItemDisplay
                              css={css`
                                flex: 0 0 auto;
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-value {
                                  text-align: center;
                                }
                              `}
                              label={
                                <>
                                  <Box
                                    css={css`
                                      height: 14px;
                                      width: 14px;
                                      border-radius: 50%;
                                      background-color: ${COLOR_PALETTE.chartFilled};
                                    `}
                                  />
                                  Allocated
                                </>
                              }
                              value={
                                <FancyCurrencyDisplay
                                  size="lg"
                                  amountCents={
                                    selectedGroup?.balanceAllocatedCents ?? 0
                                  }
                                />
                              }
                            />
                          </Box>

                          <Box
                            css={css`
                              display: flex;
                              flex: 1 1 100px;
                              justify-content: center;
                            `}
                          >
                            <DataItemDisplay
                              css={css`
                                flex: 0 0 auto;
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-value {
                                  text-align: center;
                                }
                              `}
                              label={
                                <>
                                  <Box
                                    css={css`
                                      height: 14px;
                                      width: 14px;
                                      border-radius: 50%;
                                      background-color: ${COLOR_PALETTE.chartBackground};
                                    `}
                                  />
                                  Unallocated
                                </>
                              }
                              value={
                                <FancyCurrencyDisplay
                                  size="lg"
                                  amountCents={
                                    selectedGroup?.balanceUnallocatedCents
                                  }
                                />
                              }
                            />
                          </Box>
                        </Box>
                      ),
                    },
                    {
                      label: 'Fund Assignments',
                      content: (
                        <Box
                          css={css`
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 0;
                            gap: 4px 16px;
                            flex: 1 1 auto;
                            position: relative;
                          `}
                        >
                          <PaginatedTableWrapper<
                            FundsAssignmentRaw,
                            FundsAssignmentCleaned
                          >
                            endpointUrl={
                              SERVICE_BENJI_CARD_URLS.FUND_ASSIGNMENTS_GET
                            }
                            filters={{
                              groupId: selectedGroupId ?? '',
                            }}
                            queryKeyBase={queryKeys.fundsAssignments._baseKey}
                            dataFormatter={cleanFundAssignments}
                            requiredPrivileges={[Privilege.balance]}
                            shouldUseQueryParamsSearchState={false}
                            pageSize={PAGE_SIZE}
                          >
                            {({
                              queryResult: { isLoading, isError },
                              pagination,
                              paginationData,
                              resultCount,
                              pageCount,
                            }) => (
                              <>
                                <Box
                                  css={css`
                                    display: flex;
                                    gap: 8px 16px;
                                    padding-bottom: 12px;
                                    margin: 0 14px;
                                    position: relative;
                                    border-bottom: 1px solid
                                      ${COLOR_PALETTE.borderDark};
                                    min-height: 53px;
                                  `}
                                >
                                  {privileges.fund_balance && (
                                    <Button
                                      startIcon={<MoveDownIcon />}
                                      variant="contained"
                                      onClick={() => {
                                        track({
                                          label: 'Clicked assign funds button',
                                          type: TrackType.action,
                                          actionType: 'click',
                                        });
                                        navigate(
                                          '/secure/funds-management/new-transfer',
                                          {
                                            queryParams: {
                                              'move-funds-tab': '1',
                                              selectedGroupId:
                                                selectedGroupId ?? '',
                                            },
                                          },
                                        );
                                      }}
                                    >
                                      Assign Funds
                                    </Button>
                                  )}
                                  {!!resultCount && (
                                    <Link
                                      onClick={() => {
                                        navigate('/secure/funds-management', {
                                          queryParams: {
                                            'funds-management-tab': '1',
                                            filters: JSON.stringify({
                                              groupId: selectedGroup?.id,
                                            }),
                                          },
                                        });
                                      }}
                                      css={css`
                                        margin-left: auto;
                                        font-weight: 500;
                                        line-height: normal;
                                        align-self: center;
                                        padding: 0 2px;
                                      `}
                                    >
                                      View All
                                    </Link>
                                  )}
                                </Box>

                                {!!paginationData.nodes.length && (
                                  <Box
                                    css={css`
                                      display: flex;
                                      flex-direction: column;
                                      flex: 1 1 auto;
                                      gap: 8px;
                                      padding-bottom: 12px;
                                      overflow-y: auto;
                                    `}
                                  >
                                    {paginationData.nodes.map(
                                      (fundAssignment, index) => {
                                        return (
                                          <Box
                                            key={fundAssignment.id}
                                            {...(privileges?.cards && {
                                              onClick: () =>
                                                openFundsAssignmentModal(
                                                  fundAssignment.id,
                                                ),
                                            })}
                                            css={css`
                                              display: flex;
                                              gap: 8px 16px;
                                              padding: 14px;
                                              border-bottom: 1px solid
                                                ${index + 1 === PAGE_SIZE
                                                  ? 'transparent'
                                                  : COLOR_PALETTE.border};

                                              &:hover {
                                                background-color: ${COLOR_PALETTE.tableRowHover};
                                                cursor: pointer;
                                              }
                                            `}
                                          >
                                            <Box>
                                              <Typography
                                                css={css`
                                                  font-size: 15px;
                                                  font-weight: 600;
                                                `}
                                              >
                                                {index +
                                                  1 +
                                                  ((pagination.state.page ??
                                                    1) -
                                                    1) *
                                                    PAGE_SIZE}
                                                .
                                              </Typography>
                                            </Box>
                                            <Box
                                              css={css`
                                                display: flex;
                                                gap: 12px 16px;
                                                flex-wrap: wrap;
                                              `}
                                            >
                                              <Typography
                                                css={css`
                                                  flex: 1 1 100%;
                                                  font-weight: 600;
                                                `}
                                              >
                                                {
                                                  fundAssignment.prettyCreatedAtDate
                                                }
                                              </Typography>
                                              <Box
                                                css={css`
                                                  width: 100%;
                                                  display: flex;
                                                  gap: 12px 18px;
                                                `}
                                              >
                                                <DataItemDisplay
                                                  css={css`
                                                    flex: 0 0 130px;
                                                  `}
                                                  size="small"
                                                  label="Amount"
                                                  value={
                                                    <FancyCurrencyDisplay
                                                      amountCents={
                                                        fundAssignment.amountCents
                                                      }
                                                      isPlain
                                                    />
                                                  }
                                                />
                                                <DataItemDisplay
                                                  css={css`
                                                    flex: 1 0 auto;
                                                  `}
                                                  size="small"
                                                  label="Created By"
                                                  value={
                                                    fundAssignment.createdByInternalUserName
                                                      ? fundAssignment.createdByInternalUserName
                                                      : `${fundAssignment.createdByExternalId} (via API)`
                                                  }
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        );
                                      },
                                    )}
                                  </Box>
                                )}
                                <Box
                                  css={css`
                                    padding: 0 14px;
                                  `}
                                >
                                  <TableLoader isLoading={isLoading} />
                                  <TableError
                                    isLoading={isLoading}
                                    isError={isError}
                                  />
                                  <TableNoResults
                                    isLoading={isLoading}
                                    resultCount={resultCount}
                                    noResultMessage="No fund assignments"
                                    css={css`
                                      height: initial;
                                      flex-grow: 1;
                                    `}
                                  />
                                  {(pageCount ?? 0) > 1 && (
                                    <TablePagination
                                      resultCount={resultCount}
                                      pageCount={pageCount}
                                      pagination={pagination}
                                      noSidePadding
                                    />
                                  )}
                                </Box>
                              </>
                            )}
                          </PaginatedTableWrapper>
                        </Box>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        )}
      </DialogContent>
    </Popover>
  );
};

export const GroupDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <GroupTrackingContextProvider>
      <GroupDetailsModalBase anchorEl={anchorEl} />
    </GroupTrackingContextProvider>
  );
};
