import { css } from '@emotion/react';
import { Box, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useState } from 'react';

import { AsynchronouslyLoadedSection, DataRow } from 'src/components';
import { PageTabs } from 'src/components/PageTabs';
import {
  useAppRoutes,
  useGetFundingInstructions,
  useGetUserDetails,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

import { AssignFundsToGroupForm } from './AssignFundsToGroupForm';
import checkIcon from './check-icon.png';
import copyIcon from './copy.svg';
import { UnassignFundsFromGroupForm } from './UnassignFundsFromGroupForm';

const labelWidth = 162;

const tabsKey = 'move-funds';

const handleCopyClick = (
  setIsCopySuccess: (isCopySuccess: boolean) => void,
) => {
  setIsCopySuccess(true);
  setTimeout(() => {
    setIsCopySuccess(false);
  }, 1000);
};

export const NewFundsTransfersPage = () => {
  const { navigate } = useAppRoutes();
  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;

  const [isCopyNameSuccess, setIsCopyNameSuccess] = useState(false);
  const [isCopyRoutingNumberSuccess, setIsCopyRoutingNumberSuccess] =
    useState(false);
  const [isCopyAccountNumberSuccess, setIsCopyAccountNumberSuccess] =
    useState(false);

  const {
    data: { fundingInstructions } = {},
    isLoading: isLoadingFundingInstructions,
  } = useGetFundingInstructions();

  return (
    <Box
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          gap: 20px;
          flex-wrap: wrap-reverse;
          flex: 1 1 100%;
        `}
      >
        <Typography
          component="h1"
          css={css`
            font-size: 20px;
            font-weight: 700;
          `}
        >
          Move Funds
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => navigate(-1)}
          css={css`
            background-color: ${COLOR_PALETTE.lightButtonColor};
          `}
        >
          <GridCloseIcon
            css={css`
              height: 20px;
              width: 20px;
            `}
          />
        </IconButton>
      </Box>
      <AsynchronouslyLoadedSection isLoading={isUserLoading}>
        <PageTabs
          tabsKey={tabsKey}
          tabs={[
            {
              label: 'Transfer Funds Into Account',
              width: 224,
              content: (
                <AsynchronouslyLoadedSection
                  isLoading={isLoadingFundingInstructions}
                >
                  <Box
                    css={css`
                      width: 100%;
                      max-width: 680px;
                      margin: 0 auto;
                      padding: 16px 16px 24px;
                    `}
                  >
                    <DataRow
                      label="Bank Name:"
                      value={
                        <>
                          {fundingInstructions?.bank_name}
                          <Box
                            component="button"
                            disabled={!fundingInstructions?.bank_name}
                            onClick={() => {
                              navigator.clipboard
                                .writeText(fundingInstructions?.bank_name ?? '')
                                .then(() => {
                                  handleCopyClick(setIsCopyNameSuccess);
                                })
                                .catch((error) => {
                                  console.error(
                                    'Failed to copy cardholder name',
                                    {
                                      error,
                                    },
                                  );
                                });
                            }}
                            css={css`
                              align-self: center;
                              height: 20px;
                              width: 20px;
                              border: 0;
                              border-radius: 4px;
                              padding: 0;
                              margin-left: 4px;
                              background-image: ${isCopyNameSuccess
                                ? `url(${checkIcon})`
                                : `url(${copyIcon})`};
                              background-position: center;
                              background-repeat: no-repeat;
                              background-size: 12px;
                              background-color: transparent;
                              transition: transform 0.2s;
                              ${isCopyNameSuccess
                                ? ''
                                : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                            `}
                          />
                        </>
                      }
                      labelWidth={labelWidth}
                      isPrivate
                      css={css`
                        border-bottom: initial;
                      `}
                    />
                    <DataRow
                      label="Routing Number:"
                      value={
                        <>
                          {fundingInstructions?.routing_number}
                          <Box
                            component="button"
                            disabled={!fundingInstructions?.routing_number}
                            onClick={() => {
                              navigator.clipboard
                                .writeText(
                                  fundingInstructions?.routing_number ?? '',
                                )
                                .then(() => {
                                  handleCopyClick(
                                    setIsCopyRoutingNumberSuccess,
                                  );
                                })
                                .catch((error) => {
                                  console.error(
                                    'Failed to copy cardholder name',
                                    {
                                      error,
                                    },
                                  );
                                });
                            }}
                            css={css`
                              align-self: center;
                              height: 20px;
                              width: 20px;
                              border: 0;
                              border-radius: 4px;
                              padding: 0;
                              margin-left: 4px;
                              background-image: ${isCopyRoutingNumberSuccess
                                ? `url(${checkIcon})`
                                : `url(${copyIcon})`};
                              background-position: center;
                              background-repeat: no-repeat;
                              background-size: 12px;
                              background-color: transparent;
                              transition: transform 0.2s;
                              ${isCopyRoutingNumberSuccess
                                ? ''
                                : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                            `}
                          />
                        </>
                      }
                      labelWidth={labelWidth}
                      isPrivate
                      css={css`
                        border-bottom: initial;
                      `}
                    />
                    <DataRow
                      label="Account Number:"
                      value={
                        <>
                          {fundingInstructions?.account_number}
                          <Box
                            component="button"
                            disabled={!fundingInstructions?.account_number}
                            onClick={() => {
                              navigator.clipboard
                                .writeText(
                                  fundingInstructions?.account_number ?? '',
                                )
                                .then(() => {
                                  handleCopyClick(
                                    setIsCopyAccountNumberSuccess,
                                  );
                                })
                                .catch((error) => {
                                  console.error(
                                    'Failed to copy cardholder name',
                                    {
                                      error,
                                    },
                                  );
                                });
                            }}
                            css={css`
                              align-self: center;
                              height: 20px;
                              width: 20px;
                              border: 0;
                              border-radius: 4px;
                              padding: 0;
                              margin-left: 4px;
                              background-image: ${isCopyAccountNumberSuccess
                                ? `url(${checkIcon})`
                                : `url(${copyIcon})`};
                              background-position: center;
                              background-repeat: no-repeat;
                              background-size: 12px;
                              background-color: transparent;
                              transition: transform 0.2s;
                              ${isCopyAccountNumberSuccess
                                ? ''
                                : `
                        &:hover {
                          cursor: pointer;
                          transform: scale(0.9);
                          background-color: ${COLOR_PALETTE.cardPreviewCopyButtonHover}; 
                        }
                        
                      `}
                            `}
                          />
                        </>
                      }
                      labelWidth={labelWidth}
                      isPrivate
                      css={css`
                        border-bottom: initial;
                      `}
                    />
                  </Box>
                </AsynchronouslyLoadedSection>
              ),
            },
            ...(hasGroupsLedgerEnabled
              ? [
                  {
                    label: 'Assign Funds To Group',
                    width: 184,
                    content: <AssignFundsToGroupForm />,
                  },
                  {
                    label: 'Unassign Funds From Group',
                    width: 224,
                    content: <UnassignFundsFromGroupForm />,
                  },
                ]
              : []),
          ]}
        />
      </AsynchronouslyLoadedSection>
    </Box>
  );
};
