import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

export const useUpdateCardholderStatus = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateCardholderStatus = async ({
    isCardholderActive,
    cardholderId,
  }: {
    isCardholderActive: boolean;
    cardholderId: string;
  }) => {
    const { data } = await axios.post(
      SERVICE_BENJI_CARD_URLS.CARDHOLDER_UPDATE_STATUS,
      {
        cardholder_id: cardholderId,
        new_status: isCardholderActive ? 'active' : 'inactive',
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: { isCardholderActive: boolean; cardholderId: string }) =>
      updateCardholderStatus(data),
    onSuccess: (_, { cardholderId, isCardholderActive }) => {
      const cardsQueryKey = queryKeys.cards.details({
        id: cardholderId,
      });
      queryClient.invalidateQueries(cardsQueryKey);
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.cardholders._baseKey,
      ]);
      queryClient.invalidateQueries([
        ...queryKeys.paginatedData._baseKey,
        ...queryKeys.groups._baseKey,
      ]);
      if (!isCardholderActive) {
        queryClient.invalidateQueries({
          queryKey: [
            ...queryKeys.paginatedData._baseKey,
            ...queryKeys.cards._baseKey,
          ],
        });
      }
    },
  });
};
