import { css } from '@emotion/react';
import { Box, Card, Link, Typography } from '@mui/material';

import {
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
} from 'src/components';
import { useFundsAssignmentDetailsModal } from 'src/components/modals/FundsAssignmentDetailsModal';
import { TrackType, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { Privilege } from 'src/types/user';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useOverviewCardTableTheme } from './useOverviewCardTableTheme';

import { cleanFundAssignments } from '../FundsTransfers/utils';

import type { Column } from '@table-library/react-table-library/types/compact';
import type {
  FundsAssignmentCleaned,
  FundsAssignmentRaw,
} from 'src/types/funds-assignments';

export const FundsAssignmentOverviewCard = () => {
  const endpointUrl = SERVICE_BENJI_CARD_URLS.FUND_ASSIGNMENTS_GET;

  const { track } = useTrack();
  const { navigate } = useAppRoutes();
  const { openModal: openFundsAssignmentDetailsModal } =
    useFundsAssignmentDetailsModal();

  const { privileges } = useGetUserPrivileges();
  const { data: userData } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;

  const { tableTheme } = useOverviewCardTableTheme();

  if (!hasGroupsLedgerEnabled || !privileges?.balance) {
    return null;
  }

  const columns: ({
    width: string;
  } & Column<FundsAssignmentCleaned>)[] = [
    {
      label: 'Date',
      renderCell: ({ prettyCreatedAtDate }) => {
        return prettyCreatedAtDate;
      },
      width: '95px',
    },
    {
      label: 'Group',
      renderCell: ({ group }) => {
        return group.name;
      },
      width: 'minmax(200px, 320px)',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return <FancyCurrencyDisplay amountCents={amountCents ?? 0} isPlain />;
      },
      width: '90px',
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  return (
    <Card
      css={css`
        flex: 1 1 533px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 12px;
        min-height: 260px;
      `}
    >
      <Box
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 12px 14px 0;
        `}
      >
        <Typography variant="h3">Recent Fund Assignments</Typography>
        <Link
          onClick={() => {
            track({
              label: 'Clicked view all link',
              type: TrackType.action,
              actionType: 'click',
              viewAllName: 'Fund Assignments',
            });
            navigate('/secure/funds-management', {
              queryParams: {
                'funds-management-tab': '1',
              },
            });
          }}
          css={css`
            font-weight: 500;
            line-height: normal;
          `}
        >
          View all
        </Link>
      </Box>
      <PaginatedTableWrapper<FundsAssignmentRaw, FundsAssignmentCleaned>
        endpointUrl={endpointUrl}
        queryKeyBase={queryKeys.fundsAssignments._baseKey}
        dataFormatter={cleanFundAssignments}
        requiredPrivileges={[Privilege.balance]}
        shouldUseQueryParamsSearchState={false}
        pageSize={6}
      >
        {({
          queryResult: { isLoading, isError },
          pagination,
          paginationData,
          resultCount,
        }) => (
          <>
            <Table<FundsAssignmentCleaned>
              data={paginationData}
              pagination={pagination}
              columns={columns}
              isLoading={isLoading}
              onRowClick={({ id }) => {
                openFundsAssignmentDetailsModal(id);
              }}
              columnWidths={columns.map(({ width }) => width).join(' ')}
              theme={tableTheme}
            />
            <TableLoader isLoading={isLoading} />
            <TableError isLoading={isLoading} isError={isError} />
            <TableNoResults
              isLoading={isLoading}
              resultCount={resultCount}
              noResultMessage="No Fund Assignments Yet!"
            />
          </>
        )}
      </PaginatedTableWrapper>
    </Card>
  );
};
