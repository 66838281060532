export enum TrackType {
  viewPage = 'viewPage',
  viewFeature = 'viewFeature',
  action = 'action',
  effect = 'effect',
  event = 'event',
}

export enum TrackContext {
  base = 'base',
  route = 'route',
  auth = 'auth',
  'sent-card' = 'sent-card',
  'iframe-card' = 'iframe-card',
  'app' = 'app',
  'cardholder' = 'cardholder',
  'card' = 'card',
  'transaction' = 'transaction',
  'dispute' = 'dispute',
  'funds-transfer' = 'funds-transfer',
  'funds-assignment' = 'funds-assignment',
  'sub-user' = 'sub-user',
}
