import { lowerCase, padStart } from 'lodash';

import { formatDateToMST } from 'src/utils';

import type { CardCleaned, CardRaw } from 'src/types';

export const cleanCards = (rawCards: CardRaw[]) =>
  rawCards.reduce((acc, card) => {
    const prevCard = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(new Date(card.created));
    const status = lowerCase(card.cardStatus);
    return [
      ...acc,
      {
        id: card.id,
        type: card.cardType,
        stripeId: card.stripe_card_id,
        createdAt: card.created,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevCard?.prettyCreatedAtDate === prettyCreatedAtDate,
        cardholderName: card.cardholderName,
        cardholderEmail: card.cardholderEmail,
        cardholderPhone: card.cardholderPhone,
        cardholderDob: card.cardholderDob,
        cardholderType: card.cardholderType,
        cardholderId: card.cardholderId,
        lastFour: card.cardNumber,
        expiration: {
          month: card.cardExpMonth,
          year: card.cardExpYear,
          prettyDateString: `${padStart(
            card.cardExpMonth.toString(),
            2,
            '0',
          )}/${card.cardExpYear.toString().slice(-2)}`,
        },
        billingAddress: card.cardBilling.address,
        status,
        isActive: status === 'active',
        nickname: card.cardNickname,
        totalSpendCents: card.spend,
        spendLimitCents: card.spendLimitCents,
        spendLimitRemainingCents: card.spendLimitRemainingCents,
        spentCents: card.spentCents,
        spentPendingCents: card.spentPendingCents,
        spentTotalCents:
          Math.abs(card.spentCents) + Math.abs(card.spentPendingCents),
        group: card.group,
      } as CardCleaned,
    ];
  }, [] as CardCleaned[]);
