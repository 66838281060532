import { css } from '@emotion/react';
import { Clear as ClearIcon } from '@mui/icons-material';
import { Tune as TuneIcon } from '@mui/icons-material';
import { Box, Chip, IconButton, Popover } from '@mui/material';
import { isEqual, omit } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { usePrevious } from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { formatDateToMST } from 'src/utils';
import { MSTDateStringToDateObject } from 'src/utils/date-to-mst';

import { FormFieldDateRange } from '../forms/FormFieldDateRange';
import { FormFieldSelect } from '../forms/FormFieldSelect';
import { FormFieldSwitch } from '../forms/FormFieldSwitch';

import type { PaginatedTableFilter } from '../PaginatedTableWrapper';
import type { MouseEvent, MutableRefObject, ReactNode } from 'react';

export enum FilterType {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  CHIP = 'chip',
  DATE_RANGE = 'dateRange',
}

interface FilterBase {
  name: string;
  label: string;
  widthPx?: number;
  infoPopover?: ReactNode;
}

interface FilterSelect extends FilterBase {
  type: FilterType.SELECT;
  defaultValue: string;

  options: { label: string; value: string }[];
}
interface FilterCheckbox extends FilterBase {
  type: FilterType.CHECKBOX;
  defaultValue: boolean;
}
interface FilterSwitch extends FilterBase {
  type: FilterType.SWITCH;
  defaultValue: boolean;
}
interface FilterChip extends FilterBase {
  type: FilterType.CHIP;
  defaultValue: string;
}
interface FilterDateRange extends FilterBase {
  type: FilterType.DATE_RANGE;
  defaultValue: {
    startDate: string | null;
    endDate: string | null;
  };
}
export type TableFilter =
  | FilterSelect
  | FilterCheckbox
  | FilterSwitch
  | FilterChip
  | FilterDateRange;

interface Sizes {
  parentWidth: number;
  childrenWidths: number[];
}

const useResizeObserver = (
  ref: MutableRefObject<HTMLElement | null>,
): Sizes => {
  const [sizes, setSizes] = useState<Sizes>({
    parentWidth: 0,
    childrenWidths: [],
  });

  useEffect(() => {
    if (!ref.current) return;

    const parentElement = ref.current;
    const childrenElements = Array.from(parentElement.children);

    const updateSizes = () => {
      const newSizes: Sizes = {
        parentWidth: parentElement.clientWidth,
        childrenWidths: childrenElements.map((child) => child.clientWidth),
      };
      setSizes(newSizes);
    };

    const resizeObserver = new ResizeObserver(() => {
      updateSizes();
    });

    // Observe the parent
    resizeObserver.observe(parentElement);

    // Observe children
    childrenElements.forEach((child) => resizeObserver.observe(child));

    // Initial sizes update
    updateSizes();

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return sizes;
};

const filterGap = 16; // px

const DynamicFilter = ({
  filter,
  isVisible = true,
  className,
}: {
  filter: TableFilter;
  isVisible?: boolean;
  className?: string;
}) => {
  const { control } = useFormContext();
  if (filter.type === FilterType.SELECT) {
    return (
      <FormFieldSelect
        key={filter.name}
        control={control}
        name={filter.name}
        label={
          <>
            {filter.label}
            {filter.infoPopover}
          </>
        }
        defaultValue={filter.defaultValue}
        className={className}
        css={css`
          flex: 0 0 ${filter.widthPx ?? 120}px;
          opacity: ${isVisible ? 1 : 0};
          height: ${isVisible ? 'initial' : 0};
          pointer-events: ${isVisible ? 'auto' : 'none'};
        `}
        options={filter.options}
        isSmall
        shrinkLabel
      />
    );
  } else if (filter.type === FilterType.SWITCH) {
    return (
      <FormFieldSwitch
        key={filter.name}
        name={filter.name}
        control={control}
        label={
          <>
            {filter.label}
            {filter.infoPopover}
          </>
        }
        defaultValue={filter.defaultValue}
        className={className}
        tabIndex={isVisible ? 0 : -1}
        css={css`
          opacity: ${isVisible ? 1 : 0};
          height: ${isVisible ? 'initial' : 0};
          pointer-events: ${isVisible ? 'auto' : 'none'};

          .MuiFormControlLabel-label {
            white-space: nowrap;
          }
        `}
      />
    );
  } else if (filter.type === FilterType.DATE_RANGE) {
    return (
      <FormFieldDateRange
        key={filter.name}
        name={filter.name}
        control={control}
        label={
          <>
            {filter.label}
            {filter.infoPopover}
          </>
        }
        defaultValue={filter.defaultValue}
        tabIndex={isVisible ? 0 : -1}
        disableFuture
        css={css`
          width: 320px;
          opacity: ${isVisible ? 1 : 0};
          height: ${isVisible ? 'initial' : 0};
          pointer-events: ${isVisible ? 'auto' : 'none'};

          .MuiFormControlLabel-label {
            white-space: nowrap;
          }
        `}
      />
    );
  } else if (filter.type === FilterType.CHIP) {
    return null;
  } else {
    console.error(`Filter type ${filter.type} not supported`);
    return null;
  }
};

const sortFilters = (filters: TableFilter[]) => {
  return filters.sort((a, b) => {
    if (a.type === FilterType.SELECT && b.type !== FilterType.SELECT) {
      return -1;
    } else if (a.type !== FilterType.SELECT && b.type === FilterType.SELECT) {
      return 1;
    } else {
      return 0;
    }
  });
};

const FilterMenu = ({
  isVisible = true,
  filters,
}: {
  isVisible?: boolean;
  filters: TableFilter[];
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      tabIndex={isVisible ? 0 : -1}
      css={css`
        position: absolute;
        right: 0;
        top: 0;
        opacity: ${isVisible ? 1 : 0};
        pointer-events: ${isVisible ? 'auto' : 'none'};
      `}
    >
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        tabIndex={isVisible ? 0 : -1}
      >
        <TuneIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            min-width: 200px;
          `}
        >
          {sortFilters(filters).map((filter) => (
            <DynamicFilter
              key={filter.name}
              filter={filter}
              css={css`
                flex: 0 0 auto;
                width: 100%;
              `}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

const getVisibleFilterCount = ({
  filterWidths,
  parentWidth,
  gap,
}: {
  filterWidths: number[];
  parentWidth: number;
  gap: number;
}) => {
  let count = 0;
  let currentWidth = 0;
  for (let i = 0; i < filterWidths.length; i++) {
    const newWidth = currentWidth + (filterWidths[i] ?? 0);
    if (newWidth < parentWidth) {
      count++;
      currentWidth = newWidth + gap;
    } else {
      break;
    }
  }
  return count;
};

const decodeFilters = (filters: string) => {
  try {
    return JSON.parse(decodeURIComponent(filters));
  } catch {
    console.error('Error decoding filters', filters);
    return null;
  }
};

export const TableFilters = ({
  filters,
  setFilters,
  priorityFilterCount = 2,
}: {
  filters: TableFilter[];
  setFilters: (filters: PaginatedTableFilter) => void;
  priorityFilterCount?: number;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const filtersFromQuery:
    | Record<string, TableFilter['defaultValue']>
    | undefined = searchParamsObject.filters
    ? decodeFilters(searchParamsObject.filters)
    : undefined;
  const defaultValues: Record<string, TableFilter['defaultValue']> =
    filters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.name]: filter.defaultValue,
      };
    }, {});
  const emptyValues = Object.entries(defaultValues).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]:
        typeof value === 'string' ? '' : typeof value === 'object' ? '' : false,
    }),
    {},
  );

  const useFormMethods = useForm({
    defaultValues: filtersFromQuery ?? defaultValues,
    mode: 'onBlur',
  });
  const { handleSubmit, watch, setValue, reset, formState } = useFormMethods;
  const formHasErrors = Object.keys(formState.errors).length > 0;

  const values = watch();
  const previousValues = usePrevious(values);
  useEffect(() => {
    if (!isEqual(values, previousValues)) {
      const cleanedValues = Object.entries(values)
        .filter(([, value]) => {
          const isEmptyString = value === '';
          if (isEmptyString) {
            return false;
          }
          const isEmptyObject =
            value !== null &&
            typeof value === 'object' &&
            !Object.keys(value).length;
          if (isEmptyObject) {
            return false;
          }
          const isDateFilter =
            value !== null &&
            typeof value === 'object' &&
            ('startDate' in value || 'endDate' in value);
          if (isDateFilter) {
            const hasStartDateOrEndDate =
              ('startDate' in value && !!value.startDate) ||
              ('endDate' in value && !!value.endDate);
            return hasStartDateOrEndDate;
          }
          return true;
        })
        .reduce((acc, [name, value]) => {
          return {
            ...acc,
            [name]: value,
          };
        }, {});
      if (!formHasErrors) {
        setFilters(cleanedValues);
      }
    }
  }, [formHasErrors, previousValues, setFilters, values]);

  const dirtyValues = Object.entries(values).reduce((acc, [name, value]) => {
    const defaultValue = defaultValues[name];
    const isSameAsDefault =
      typeof defaultValue === 'object'
        ? isEqual(defaultValue, value)
        : defaultValue === value;
    if (!isSameAsDefault) {
      return {
        ...acc,
        [name]: value,
      };
    } else {
      return acc;
    }
  }, {});
  const filterQueryParam = Object.keys(dirtyValues).length
    ? JSON.stringify(dirtyValues)
    : '';
  const previousFilterQueryParam = usePrevious(filterQueryParam);
  useEffect(() => {
    if (filterQueryParam !== previousFilterQueryParam) {
      if (filterQueryParam) {
        setSearchParams(
          { ...searchParamsObject, filters: filterQueryParam },
          { replace: true },
        );
      } else {
        setSearchParams(omit(searchParamsObject, 'filters'), { replace: true });
      }
    }
  }, [
    filterQueryParam,
    previousFilterQueryParam,
    searchParamsObject,
    setSearchParams,
  ]);

  const filterContainerRef = useRef<HTMLDivElement>(null);
  const { parentWidth, childrenWidths } = useResizeObserver(filterContainerRef);
  const filterWidths = childrenWidths.slice(0, -1);
  const menuWidth = childrenWidths[childrenWidths.length - 1];
  const visibleFilterCount = Math.min(
    priorityFilterCount,
    getVisibleFilterCount({
      filterWidths,
      parentWidth: parentWidth - (menuWidth ?? 0),
      gap: filterGap,
    }),
  );
  const hasNonChipFilters =
    filters.filter((filter) => filter.type !== FilterType.CHIP).length > 0;
  const showMenu = hasNonChipFilters && visibleFilterCount < filters.length;
  const menuFilters = filters.slice(visibleFilterCount);
  const areAllFiltersChips = filters.every(
    (filter) => filter.type === FilterType.CHIP,
  );

  const activeFilters = Object.entries(values)
    .map(([name, value]) => {
      const filter = filters.find((filter) => filter.name === name);
      if (!filter) {
        console.error(`Filter with name ${name} not found`);
        return null;
      }
      let label = `${filter.label} - ${value}`;
      if (filter.type === FilterType.SELECT) {
        label = `${filter.label} - ${filter.options.find(
          (option) => option.value === value,
        )?.label}`;
      } else if (filter.type === FilterType.SWITCH) {
        label = filter.label;
      } else if (filter.type === FilterType.CHIP) {
        label = filter.label;
      } else if (filter.type === FilterType.DATE_RANGE) {
        const startDate =
          typeof value === 'object' &&
          !!value?.startDate &&
          value.startDate !== null
            ? MSTDateStringToDateObject(value.startDate)
            : null;
        const endDate =
          typeof value === 'object' &&
          !!value?.endDate &&
          value.endDate !== null
            ? MSTDateStringToDateObject(value.endDate)
            : null;
        if (startDate && endDate) {
          label = `${filter.label} ${formatDateToMST(
            startDate,
          )} - ${formatDateToMST(endDate)}`;
        } else if (startDate) {
          label = `${filter.label} after ${formatDateToMST(startDate)}`;
        } else if (endDate) {
          label = `${filter.label} before ${formatDateToMST(endDate)}`;
        }
      }
      return {
        name,
        label,
        value,
        type: filter.type,
      };
    })
    .filter((filter) => filter !== null)
    .filter((filter) => {
      if (filter?.type === FilterType.SELECT) {
        return !!filter.value;
      } else if (filter?.type === FilterType.SWITCH) {
        return !!filter.value;
      } else if (filter?.type === FilterType.CHIP) {
        return !!filter.value;
      } else if (filter?.type === FilterType.DATE_RANGE) {
        return (
          typeof filter.value == 'object' &&
          !!(
            ('startDate' in filter.value && filter.value.startDate) ||
            ('endDate' in filter.value && filter.value.endDate)
          )
        );
      } else {
        return true;
      }
    }) as {
    name: string;
    label: string;
    type: FilterType;
  }[];

  // TODO: get rid of this
  const onSubmit = async (values: typeof defaultValues) => {
    console.log(values);
  };

  return (
    <FormProvider {...useFormMethods}>
      <Box
        css={css`
          flex-wrap: wrap;
          gap: 8px ${filterGap}px;
          width: 100%;
          display: ${activeFilters.length === 0 && areAllFiltersChips
            ? 'none'
            : 'flex'};
        `}
      >
        <Box
          css={css`
            display: flex;
            flex-wrap: nowrap;
            gap: 8px ${filterGap}px;
            width: 100%;
            position: relative;
            align-items: flex-end;
          `}
        >
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            css={css`
              display: flex;
              flex-wrap: nowrap;
              flex: 1 1 200px;
              gap: 8px ${filterGap}px;
              overflow: hidden;
              padding-top: 6px;
              min-height: ${showMenu ? '44px' : '0px'};
            `}
            ref={filterContainerRef}
          >
            {filters.map((filter, i) => {
              const isVisible = i < visibleFilterCount;
              return (
                <DynamicFilter
                  key={filter.name}
                  filter={filter}
                  isVisible={isVisible}
                />
              );
            })}
            <FilterMenu isVisible={showMenu} filters={menuFilters} />
          </Box>
        </Box>
        <Box
          css={css`
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            min-height: 24px;
          `}
        >
          {activeFilters.map(({ name, label }) => {
            return (
              <Chip
                key={name}
                label={label}
                onDelete={() => {
                  setValue(name, '');
                }}
                onClick={() => {
                  setValue(name, '');
                }}
                size="small"
                variant="filled"
                css={css`
                  background-color: ${COLOR_PALETTE.filterChip};
                  .MuiChip-label {
                    font-size: 11px;
                  }
                `}
              />
            );
          })}
          {!!activeFilters.length && (
            <Chip
              label="Clear"
              onClick={() => reset(emptyValues)}
              size="small"
              icon={<ClearIcon />}
              css={css`
                margin-left: auto;
                border-color: ${COLOR_PALETTE.filterChip};
                .MuiChip-label {
                  font-size: 11px;
                }
              `}
              variant="outlined"
            />
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};
