export enum AccountStatus {
  CLOSED = 'closed',
  PENDING_ONBOARDING = 'pendingOnboarding',
  PENDING_CAPABILITIES = 'pendingCapabilities',
  PENDING_APPROVAL = 'pendingApproval',
  PENDING_FINANCIAL_ACCOUNT_FEATURES = 'pendingFinancialAccountFeatures',
  ACTIVE = 'active',
}

export enum StripeAccountOnboardingStatus {
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
}
