export enum TopupStatusEnum {
  CANCELED = 'canceled',
  FAILED = 'failed',
  PENDING = 'pending',
  REVERSED = 'reversed',
  SUCCEEDED = 'succeeded',
}
export enum PayoutStatusEnum {
  PAID = 'paid',
  PENDING = 'pending',
  IN_TRANSIT = 'in_transit',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export interface FundsTransferRaw {
  id: string;
  stripe_id: string;
  created: string;
  amount: number;
  status: string;
  expected_date: string;
  entity_type: string;
}

export interface FundsTransferCleaned {
  id: FundsTransferRaw['id'];
  stripeId: FundsTransferRaw['stripe_id'];
  createdAt: FundsTransferRaw['created'];
  prettyCreatedAtDate: string;
  isCreatedAtDateSameAsPrev: boolean;
  amountCents: FundsTransferRaw['amount'];
  status: FundsTransferRaw['status'];
  type: FundsTransferRaw['entity_type'];
}

export interface FundsTransferDetailsRaw {
  id: string;
  stripe_id: string;
  created: string;
  amount: number;
  status: string;
  expected_date: string;
  entity_type: string;
}

export interface FundsTransferDetailsCleaned {
  id: FundsTransferDetailsRaw['id'];
  stripeId: FundsTransferDetailsRaw['stripe_id'];
  createdAt: FundsTransferDetailsRaw['created'];
  prettyCreatedAtDate: string;
  amountCents: FundsTransferDetailsRaw['amount'];
  status: FundsTransferDetailsRaw['status'];
  type: FundsTransferDetailsRaw['entity_type'];
}
