import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';

import { AccountStatus } from 'src/types/account';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useAppRoutes } from './useAppRoutes';
import { useAxios } from './useAxios';

import type { Address } from 'src/types';
import type { CardType } from 'src/types/cards';

export interface UserDetails {
  userDetails: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    status: string;
  };
  accountDetails: {
    status: AccountStatus;
    companyName: string;
    companyAddress: Address;
    companySupportEmail: string;
    companyCardTypes: CardType[];
    features: {
      groups: boolean;
      groupsLedger: boolean;
      sendCardsViaEmail: boolean;
    };
  };
}

export const useGetUserDetails = () => {
  const { axios, generateSecureHeaders } = useAxios();

  const { isSignedIn, user } = useUser();
  const { isAuthenticatedRoute } = useAppRoutes();
  const getUserDetails = async () => {
    const { data } = await axios.get<UserDetails>(
      SERVICE_BENJI_CARD_URLS.USER_GET_DETAILS,
      {
        headers: await generateSecureHeaders(),
      },
    );
    const isOnboarding = (
      [
        AccountStatus.PENDING_ONBOARDING,
        AccountStatus.PENDING_CAPABILITIES,
        AccountStatus.PENDING_APPROVAL,
        AccountStatus.PENDING_FINANCIAL_ACCOUNT_FEATURES,
      ] as string[]
    ).includes(data.accountDetails?.status ?? '');
    return { ...data, isOnboarding };
  };

  return useQuery({
    queryFn: () => getUserDetails(),
    queryKey: queryKeys.account.details({
      userId: user?.externalId ?? '',
    }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!isSignedIn && !!isAuthenticatedRoute,
  });
};
