import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAxios } from 'src/hooks/useAxios';
import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

export const useUpdateCardSpendControlAmount = () => {
  const queryClient = useQueryClient();
  const { axios, generateSecureHeaders } = useAxios();

  const updateCardSpendControlAmount = async ({
    cardSpendControlAmountDollars,
    cardId,
  }: {
    cardSpendControlAmountDollars: number | null;
    cardId: string;
  }) => {
    const { data } = await axios.post(
      cardSpendControlAmountDollars
        ? SERVICE_BENJI_CARD_URLS.CARD_UPDATE_SPEND_CONTROL
        : SERVICE_BENJI_CARD_URLS.CARD_CLEAR_SPEND_CONTROL,
      {
        stripe_card_id: cardId,
        ...(cardSpendControlAmountDollars && {
          amount: cardSpendControlAmountDollars,
        }),
      },
      {
        headers: await generateSecureHeaders(),
      },
    );
    return data;
  };

  return useMutation({
    mutationFn: (data: {
      cardSpendControlAmountDollars: number | null;
      cardId: string;
    }) => updateCardSpendControlAmount(data),
    onSuccess: (_, { cardId }) => {
      const cardQueryKey = queryKeys.cards.details({
        id: cardId,
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.cards._baseKey,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.balance.current(),
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...queryKeys.paginatedData._baseKey,
          ...queryKeys.groups._baseKey,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: cardQueryKey,
      });
    },
  });
};
