export enum GroupStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum GroupOrderByEnum {
  NAME = 'name',
  PERCENT_ALLOCATED = 'percent_allocated',
}

export interface GroupRaw {
  id: string;
  name: string;
  status: GroupStatus;
  balance_available_cents: number;
  balance_current_cents: number;
  balance_allocated_cents: number;
  balance_allocated_percent: number;
  balance_unallocated_cents: number;
}

export interface GroupCleaned {
  id: GroupRaw['id'];
  name: GroupRaw['name'];
  status: GroupRaw['status'];
  balanceAllocatedCents: GroupRaw['balance_allocated_cents'];
  balanceAvailableCents: GroupRaw['balance_available_cents'];
  balanceCurrentCents: GroupRaw['balance_current_cents'];
  balanceUnallocatedCents: GroupRaw['balance_unallocated_cents'];
  balancePercentAllocated: GroupRaw['balance_allocated_percent'];
}
export interface GroupDetailsRaw extends GroupRaw {}
export interface GroupDetailsCleaned extends GroupCleaned {}
