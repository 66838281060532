import { css } from '@emotion/react';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Box, Card, Link, Typography } from '@mui/material';

import {
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
} from 'src/components';
import { useGroupDetailsModal } from 'src/components/modals/GroupDetailsModal';
import { TrackType, useTrack } from 'src/context';
import {
  useAppRoutes,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import {
  GroupOrderByEnum,
  GroupStatus,
  type GroupCleaned,
  type GroupRaw,
} from 'src/types/groups';
import { Privilege } from 'src/types/user';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { useOverviewCardTableTheme } from './useOverviewCardTableTheme';

import { cleanGroups } from '../Groups/utils';

import type { TableColumn } from 'src/components/Table';

export const GroupsOverviewCard = () => {
  const endpointUrl = SERVICE_BENJI_CARD_URLS.GROUPS_GET;

  const { track } = useTrack();
  const { navigate } = useAppRoutes();
  const { openModal: openGroupDetailsModal } = useGroupDetailsModal();

  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;

  const { privileges } = useGetUserPrivileges();

  const { tableTheme } = useOverviewCardTableTheme();

  if (!hasGroupsLedgerEnabled || !privileges?.balance) {
    return null;
  }

  const columns: ({
    width: string;
  } & TableColumn<GroupCleaned>)[] = [
    {
      label: 'Name',
      renderCell: ({ name }) => name,
      width: 'minmax(150px, 300px)',
    },
    ...(privileges.balance
      ? [
          {
            label: 'Available',
            renderCell: ({ balanceAvailableCents }: GroupCleaned) => {
              return (
                <FancyCurrencyDisplay
                  amountCents={balanceAvailableCents ?? 0}
                  isPlain
                />
              );
            },
            width: '140px',
          },
        ]
      : []),
    {
      label: 'Allocated',
      renderLabel: () => {
        return (
          <Box
            css={css`
              display: flex;
              font-weight: 600;
              color: ${COLOR_PALETTE.textOnLight};
            `}
          >
            Allocated
            <Box
              css={css`
                position: relative;
                height: 12px;
                width: 12px;
              `}
            >
              <ArrowDropDownIcon
                css={css`
                  height: 16px;
                  width: 16px;
                  position: absolute;
                  top: -3px;
                  left: -2px;
                `}
              />
            </Box>
          </Box>
        );
      },
      renderCell: ({
        balanceAvailableCents,
        balanceAllocatedCents,
        balancePercentAllocated,
      }: GroupCleaned) => {
        const percent = Number((balancePercentAllocated * 100).toFixed(2));
        const isOver100 = percent > 100;
        return (
          <Box
            css={css`
              display: flex;
              gap: 8px;
              align-items: baseline;
            `}
          >
            <FancyCurrencyDisplay
              amountCents={balanceAllocatedCents ?? 0}
              isPlain
            />
            {privileges.balance && (
              <Typography
                css={css`
                  color: ${isOver100 ? COLOR_PALETTE.error : 'inherit'};
                  font-size: 15px;
                  font-weight: 300;
                `}
              >
                {balanceAvailableCents === 0 &&
                balanceAllocatedCents === 0 ? null : (
                  <>({isOver100 ? '>100' : percent}%)</>
                )}
              </Typography>
            )}
          </Box>
        );
      },
      width: '200px',
    },
    {
      label: 'Status',
      width: '120px',
      renderCell: ({ status }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type="group" />
          </Box>
        );
      },
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  return (
    <Card
      css={css`
        flex: 1 1 533px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 12px;
        min-height: 260px;
      `}
    >
      <Box
        css={css`
          display: flex;
          padding: 12px 14px 0;
          align-items: baseline;
        `}
      >
        <Typography variant="h3">Groups</Typography>
        <Link
          onClick={() => {
            track({
              label: 'Clicked view all link',
              type: TrackType.action,
              actionType: 'click',
              viewAllName: 'Groups',
            });
            navigate('/secure/groups');
          }}
          css={css`
            font-weight: 500;
            line-height: normal;
            margin-left: auto;
          `}
        >
          View all
        </Link>
      </Box>
      <PaginatedTableWrapper<GroupRaw, GroupCleaned>
        endpointUrl={endpointUrl}
        queryKeyBase={queryKeys.groups._baseKey}
        dataFormatter={cleanGroups}
        requiredPrivileges={[Privilege.cards]}
        shouldUseQueryParamsSearchState={false}
        pageSize={6}
        orderBy={GroupOrderByEnum.PERCENT_ALLOCATED}
        filters={{
          status: GroupStatus.ACTIVE,
        }}
      >
        {({
          queryResult: { isLoading, isError },
          pagination,
          paginationData,
          resultCount,
        }) => (
          <>
            <Table<GroupCleaned>
              data={paginationData}
              pagination={pagination}
              columns={columns}
              isLoading={isLoading || isUserLoading}
              onRowClick={({ id }) => {
                openGroupDetailsModal(id);
              }}
              columnWidths={columns.map(({ width }) => width).join(' ')}
              theme={tableTheme}
            />
            <TableLoader isLoading={isLoading} />
            <TableError isLoading={isLoading} isError={isError} />
            <TableNoResults
              isLoading={isLoading}
              resultCount={resultCount}
              noResultMessage="No groups yet!"
            />
          </>
        )}
      </PaginatedTableWrapper>
    </Card>
  );
};
