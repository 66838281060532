import { css } from '@emotion/react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  InfoPopover,
} from 'src/components';
import { CircleChart } from 'src/components/CircleChart';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import {
  useGetAccountOverview,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';

export const AccountBalancesOverviewCard = () => {
  const { privileges, isLoading: areUserPrivilegesLoading } =
    useGetUserPrivileges();

  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;

  const { data: accountOverview, isLoading: isBalanceLoading } =
    useGetAccountOverview();
  const { accountBalance } = accountOverview ?? {};

  const isLoading =
    areUserPrivilegesLoading || isBalanceLoading || isUserLoading;

  if (!privileges?.balance) {
    return null;
  }

  return (
    <Card
      css={css`
        flex: 1 1 400px;
      `}
    >
      <CardContent
        css={css`
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          gap: 24px;
          justify-content: space-between;
          padding: 12px 14px;
        `}
      >
        <AsynchronouslyLoadedSection isLoading={isLoading}>
          {hasGroupsLedgerEnabled ? (
            <>
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0 16px;
                  flex: 1 1 150px;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    flex: 1 1 100%;
                  `}
                >
                  <Typography variant="h3">Account Balance</Typography>
                </Box>
                <Box
                  css={css`
                    flex: 1 1 100%;
                    margin-bottom: 16px;
                  `}
                >
                  <DataItemDisplay
                    css={css`
                      flex: 1 1 100px;
                      gap: 0;
                    `}
                    size="large"
                    label="Available"
                    value={
                      <FancyCurrencyDisplay
                        size="xl"
                        amountCents={accountBalance?.availableCents}
                        className="highlight-block"
                      />
                    }
                  />
                </Box>

                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1 1 auto;
                  `}
                >
                  <DataItemDisplay
                    css={css`
                      flex: 1 1 140px;
                    `}
                    size="medium"
                    label={
                      <>
                        Assigned
                        <InfoPopover
                          contents="The portion of the available balance that has been assigned to a group."
                          name="Assigned Balance"
                        />
                      </>
                    }
                    value={
                      <FancyCurrencyDisplay
                        size="lg"
                        amountCents={accountBalance?.assignedAvailableCents}
                        className="highlight-block"
                      />
                    }
                  />
                </Box>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1 1 auto;
                  `}
                >
                  <DataItemDisplay
                    css={css`
                      flex: 1 1 100px;
                    `}
                    size="medium"
                    label={
                      <>
                        Unassigned
                        <InfoPopover
                          contents="The portion of the available balance that has not been assigned to a group. This amount cannot be spent on cards until it is assigned to a group."
                          name="Unassigned Balance"
                        />
                      </>
                    }
                    value={
                      <FancyCurrencyDisplay
                        css={css`
                          flex: 1 1 100%;
                          font-size: 24px;
                          line-height: 1.3;
                          display: block;
                        `}
                        size="lg"
                        amountCents={accountBalance?.unassignedAvailableCents}
                        className="highlight-block"
                      />
                    }
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  gap: 0 16px;
                  flex: 1 1 150px;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    flex: 1 1 100%;
                  `}
                >
                  <Typography variant="h3">Account Balance</Typography>
                </Box>
                <Box
                  css={css`
                    flex: 1 1 100%;
                    margin-bottom: 16px;
                  `}
                >
                  <DataItemDisplay
                    css={css`
                      flex: 1 1 100px;
                      gap: 0;
                    `}
                    size="large"
                    label="Available"
                    value={
                      <FancyCurrencyDisplay
                        size="xl"
                        amountCents={accountBalance?.availableCents}
                        className="highlight-block"
                      />
                    }
                  />
                </Box>

                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1 1 auto;
                  `}
                >
                  <DataItemDisplay
                    css={css`
                      flex: 1 1 140px;
                    `}
                    size="medium"
                    label={
                      <>
                        <Box
                          css={css`
                            height: 14px;
                            width: 14px;
                            border-radius: 50%;
                            background-color: ${COLOR_PALETTE.chartFilled};
                          `}
                        />
                        Allocated
                        <InfoPopover
                          contents="This is the total of every active card's spend limit minus the amount already spent on the card. In other words, this is the amount remaining for every card to hit its spend limit max."
                          name="Allocated Balance"
                        />
                      </>
                    }
                    value={
                      <FancyCurrencyDisplay
                        size="lg"
                        amountCents={accountBalance?.allocatedAvailableCents}
                        className="highlight-block"
                      />
                    }
                  />
                </Box>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1 1 auto;
                  `}
                >
                  <DataItemDisplay
                    css={css`
                      flex: 1 1 100px;
                    `}
                    size="medium"
                    label={
                      <>
                        <Box
                          css={css`
                            height: 14px;
                            width: 14px;
                            border-radius: 50%;
                            background-color: ${COLOR_PALETTE.chartBackground};
                          `}
                        />
                        Unallocated
                        <InfoPopover
                          contents="This is the available account balance minus the total unspent amount on all active cards with a spend limit. In other words, this is the amount remaining that has yet to be allocated to a card via spend limit. A negative amount indicate an overallocation of your available balance."
                          name="Unallocated Balance"
                        />
                      </>
                    }
                    value={
                      <FancyCurrencyDisplay
                        css={css`
                          flex: 1 1 100%;
                          font-size: 24px;
                          line-height: 1.3;
                          display: block;
                        `}
                        size="lg"
                        amountCents={accountBalance?.unallocatedAvailableCents}
                        className="highlight-block"
                      />
                    }
                  />
                </Box>
              </Box>
              <CircleChart
                value={accountBalance?.allocatedAvailableCents ?? 0}
                max={accountBalance?.availableCents ?? 0}
                css={css`
                  margin: 0 auto;
                `}
              />
            </>
          )}
        </AsynchronouslyLoadedSection>
      </CardContent>
    </Card>
  );
};
