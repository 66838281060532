import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, formatDateToMST, queryKeys } from 'src/utils';

import { useAxios } from './useAxios';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type {
  FundsAssignmentDetailsCleaned,
  FundsAssignmentDetailsRaw,
} from 'src/types/funds-assignments';

export const useGetFundsAssignment = ({ id }: { id: string }) => {
  const { axios, generateSecureHeaders } = useAxios();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getFundsAssignment = async ({ id }: { id: string }) => {
    const { data: fundsAssignment } =
      await axios.get<FundsAssignmentDetailsRaw>(
        `${generatePath(SERVICE_BENJI_CARD_URLS.FUND_ASSIGNMENTS_GET_DETAILS, {
          fundAssignmentId: id,
        })}`,
        {
          headers: await generateSecureHeaders(),
        },
      );
    const prettyCreatedAtDate = formatDateToMST(
      new Date(fundsAssignment.created_at),
    );

    return {
      id: fundsAssignment.id,
      createdAt: fundsAssignment.created_at,
      prettyCreatedAtDate,
      createdByInternalUserId: fundsAssignment.created_by_internal_user_id,
      createdByInternalUserName: fundsAssignment.user
        ? [
            fundsAssignment.user.first_name,
            fundsAssignment.user.last_name,
          ].join(' ')
        : null,
      createdByExternalId: fundsAssignment.created_by_external_id,
      createdByLinkedTopupSourceId: null,
      topupId: null,
      topupSavedSource: fundsAssignment.topup_saved_source,
      group: {
        name: fundsAssignment.group.name,
        id: fundsAssignment.group.id,
      },
      amountCents: fundsAssignment.amount_cents,
    } as FundsAssignmentDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.fundsAssignments.details({
      id,
    }),
    queryFn: () =>
      getFundsAssignment({
        id,
      }),
    enabled: !!isSignedIn && !!privileges?.balance && !!id,
  });
};
