import { lowerCase } from 'lodash';

import { formatDateToMST } from 'src/utils';

import type {
  FundsAssignmentCleaned,
  FundsAssignmentRaw,
} from 'src/types/funds-assignments';
import type {
  FundsTransferCleaned,
  FundsTransferRaw,
} from 'src/types/funds-transfers';

export const cleanBalanceTransfers = (
  rawBalanceTransfers: FundsTransferRaw[],
) =>
  rawBalanceTransfers.reduce((acc, balanceTransfer) => {
    const prevBalanceTransfer = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(
      new Date(balanceTransfer.created),
    );
    const status = lowerCase(balanceTransfer.status);
    const amountCents =
      balanceTransfer.amount *
      (balanceTransfer.entity_type === 'payout' ? -1 : 1);
    const type =
      balanceTransfer.entity_type === 'payout' && amountCents < 0
        ? 'Payout To Bank'
        : balanceTransfer.entity_type === 'topup' && amountCents > 0
        ? 'External Transfer'
        : 'Spend';
    return [
      ...acc,
      {
        id: balanceTransfer.id,
        stripeId: balanceTransfer.stripe_id,
        createdAt: balanceTransfer.created,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevBalanceTransfer?.prettyCreatedAtDate === prettyCreatedAtDate,
        amountCents,
        status,
        type,
      } as FundsTransferCleaned,
    ];
  }, [] as FundsTransferCleaned[]);

export const cleanFundAssignments = (
  rawFundAssignments: FundsAssignmentRaw[],
) =>
  rawFundAssignments.reduce((acc, fundsAssignment) => {
    const prevFundsAssignment = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(
      new Date(fundsAssignment.created_at),
    );
    return [
      ...acc,
      {
        id: fundsAssignment.id,
        amountCents: fundsAssignment.amount_cents,
        createdAt: fundsAssignment.created_at,
        prettyCreatedAtDate,
        isCreatedAtSameAsPrev:
          prevFundsAssignment?.prettyCreatedAtDate === prettyCreatedAtDate,
        createdByExternalId: fundsAssignment.created_by_external_id,
        createdByInternalUserId: fundsAssignment.created_by_internal_user_id,
        createdByInternalUserName:
          fundsAssignment.user &&
          [
            fundsAssignment.user.first_name,
            fundsAssignment.user.last_name,
          ].join(' '),
        createdByLinkedTopupSourceId:
          fundsAssignment.created_by_linked_topup_source_id,
        group: fundsAssignment.group,
        accountId: fundsAssignment.account_id,
        topupId: fundsAssignment.topup_id,
      } as FundsAssignmentCleaned,
    ];
  }, [] as FundsAssignmentCleaned[]);
