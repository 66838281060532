import { useTheme } from '@table-library/react-table-library/theme';

import { COLOR_PALETTE } from 'src/theme';

export const useOverviewCardTableTheme = () => {
  const tableTheme = useTheme({
    HeaderCell: `
          color: ${COLOR_PALETTE.dataItemDisplayLabel};
          font-size: 10px;
          font-weight: 600;
          line-height: normal;
          padding: 8px 10px;
          &:first-of-type {
            padding-left: 14px;
          }
          &:last-child {
            padding-right: 14px;
          }
        `,
    Cell: `
          padding: 5px 10px;
          font-size: 12px;
          font-weight: 400;
          color: ${COLOR_PALETTE.textOnLight};
          min-height: 34px;
          &:first-of-type {
            padding-left: 14px;
          }
          &:last-child {
            padding-right: 14px;
          }
        `,
  });
  return { tableTheme };
};
